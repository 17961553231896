import {
  BillingAndRequestCardFormValues,
  BillingInfo,
} from 'components/BillingInfo/types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  SignupFormData,
  useCreateAccount,
  useInitialSignupState,
  useLogin,
  useSubmitCardRequest,
  useValidateBillingInfo,
  useValidatePersonalDetails,
} from 'screens/Auth/SignupV2/api';
import { useSignupErrorFormatter } from 'screens/Auth/SignupV2/errors';
import {
  AccountVerifiedStep,
  BillingInfoStep,
  Journey,
  PersonalDetailsStep,
  SignupStep,
  useSignupJourney,
  VerifyEmailStep,
} from 'screens/Auth/SignupV2/journey';
import { Container, Grid, Segment } from 'semantic';
import LanguageDropdownV2 from 'components/LanguageDropdownV2';
import { isNotProd } from 'components/environmentBanner';
import { useProvider } from 'screens/Auth/SignupV2/useProvider';
import PersonalDetailsForm, {
  PersonalAndAccountDetails,
} from 'screens/Auth/SignupV2/steps/PersonalDetails';
import Panel from 'screens/Auth/SignupV2/Panel';
import VerifyEmail from 'screens/Auth/SignupV2/steps/VerifyEmail';
import BillingInfoForm, {
  billingInfoByAccountType,
} from 'screens/Auth/SignupV2/steps/BillingInfo';
import logoInverted from 'assets/logo-text.svg';
import { getThemedField } from 'utils/theming';
import styles from './main.module.less';
import { useProductInstrumentation } from 'contexts/productInstrumentation';
import ProgressSteps from 'components/ProgressSteps';
import { AccountDetails } from 'screens/Auth/SignupV2/steps/AccountDetails';
import { useSubmitBillingInfoMutation } from 'components/BillingInfo/api';
import { Account } from 'types/account';
import { useUser } from 'contexts/user';
import LoginPanel from 'screens/Auth/SignupV2/LoginPanel';
import RedirectScreen, {
  Variation,
} from 'screens/Auth/SignupV2/steps/Completed/RedirectScreen';

import { identifyUser, trackCustomBehavioralEvent } from 'utils/hubspot';
import { countries } from 'eflux-pkg-js';
import { INTERCOM_APP_ID } from 'utils/env';
import { useCanUseAutoPay } from 'hooks/usePaymentMethodOptions';

export default function SignupV2WithQueryProvider() {
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: { mutations: { networkMode: 'always' } },
      }),
    []
  );
  useEffect(() => () => queryClient.clear(), []);
  return (
    <QueryClientProvider client={queryClient}>
      <SignupV2 />
    </QueryClientProvider>
  );
}

function SignupV2() {
  const { t } = useTranslation();
  const provider = useProvider();
  const { setToken } = useUser() as { setToken: (token: string) => void };
  const errorFormatter = useSignupErrorFormatter();
  const canUseAutoPay = useCanUseAutoPay();

  const headerBackgroundColor = getThemedField(
    provider,
    'secondaryBackgroundColorHex',
    false
  );

  const logoPath = (provider && provider.logoUrl) || logoInverted;

  const signupJourney = useSignupJourney();

  const [step, setStep] = useState('');
  const [navigationDirection, setNavigationDirection] = useState<
    'forward' | 'backward' | 'none'
  >('none');

  const navigateForward = () => {
    const nextStep = signupJourney.nextStep(step as SignupStep);
    if (nextStep) {
      setNavigationDirection('forward');
      setStep(nextStep);
    }
  };

  useEffect(() => {
    if (window.Intercom && provider && provider.slug === 'e-flux') {
      window.Intercom('boot', {
        app_id: INTERCOM_APP_ID,
      });
    }
  }, [provider]);

  const [account, setAccount] = useState<Account>({
    id: '',
    type: 'individual',
    billingCurrency: 'EUR',
    billing: {
      accountHolderName: '',
      ibanNo: '',
      bicNo: '',
      countryCode: '',
      address: '',
      city: '',
      postalCode: '',
      paymentMethod: 'autopay',
      sepaAccepted: false,
    },
    contact: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNo: '',
      phoneCountryCode: '',
      jobTitle: '',
    },
    chamberOfCommerceNo: '',
    vatNo: '',
    hasNoVatNo: false,
    fieldService: {
      email: '',
    },
    accountTierBillingPlanId: {
      id: '',
    },
  });

  const [personalAndAccountDetails, setPersonalAndAccountDetails] =
    useState<PersonalAndAccountDetails>({
      // Personal details
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      countryCode: '',
      phoneCountryCode: '',
      phoneNo: '',
      acceptedPrivacy: false,
      acceptedTerms: false,
      newsletter: false,
      // Account Details
      accountType: 'individual',
    });

  const [billingInfo, setBillingInfo] = useState<BillingInfo>({
    paymentMethod: 'autopay',
    accountHolderName: '',
    ibanNumber: '',
    bicNumber: '',
    countryCode: '',
    street: '',
    number: '',
    postalCode: '',
    city: '',
    sepaAccepted: false,
  });

  const [billingAndRequestCardFormValues, setBillingAndRequestCardFormValues] =
    useState<BillingAndRequestCardFormValues>({
      billing: billingInfo,
      cardHolder: {
        type: 'existing',
        userId: '',
      },
      card: {
        type: 'card',
        billingPlanId: '',
      },
      delivery: {
        useSameAsBilling: true,
      },
      accountDetails: {
        accountType: 'individual',
      },
    });

  const [submittedCardRequest, setSubmittedCardRequest] = useState(false);
  // synchronize billingAndRequestCardFormValues with billingInfo
  useEffect(() => {
    setBillingAndRequestCardFormValues((values) => ({
      ...values,
      billing: billingInfo,
    }));
  }, [billingInfo]);

  // synchronize billingAndRequestCardFormValues with personalAndAccountDetails
  useEffect(() => {
    setBillingAndRequestCardFormValues((prevValues) => {
      if (prevValues.cardHolder.type === 'existing') {
        const user = prevValues.cardHolder.user || {
          id: prevValues.cardHolder.userId,
          contact: {
            firstName: personalAndAccountDetails.firstName,
            lastName: personalAndAccountDetails.lastName,
          },
          address: {
            postalCode: '',
            city: '',
            countryCode: '',
          },
          name: '',
        };

        return {
          ...prevValues,
          cardHolder: {
            ...prevValues.cardHolder,
            user: {
              ...user,
              contact: {
                ...user.contact,
                firstName: personalAndAccountDetails.firstName,
                lastName: personalAndAccountDetails.lastName,
                phoneNo: personalAndAccountDetails.phoneNo,
                phoneCountryCode: personalAndAccountDetails.phoneCountryCode,
              },
            },
          },
        };
      }
      return prevValues;
    });
  }, [personalAndAccountDetails]);

  // load initial state
  const initialState = useInitialSignupState();
  useEffect(() => {
    if (initialState.data) {
      setPersonalAndAccountDetails({
        ...personalAndAccountDetails,
        countryCode:
          initialState.data?.formState?.countryCode ||
          personalAndAccountDetails.countryCode,
        phoneCountryCode:
          initialState.data?.formState?.countryCode?.toUpperCase?.() ||
          personalAndAccountDetails.phoneCountryCode,
      });

      setBillingInfo((billingInfo) => ({
        ...billingInfo,
        countryCode:
          initialState.data?.formState?.countryCode ||
          personalAndAccountDetails.countryCode,
      }));
    }
    if (!initialState.isLoading) {
      setStep(PersonalDetailsStep);
      setNavigationDirection('forward');
    }
  }, [initialState.data, initialState.isLoading]);

  const personalAndAccountDetailsFormRef =
    useRef<FormikProps<PersonalAndAccountDetails> | null>(null);

  // Function to validate personal details and then create account
  const validatePersonalDetailsAndCreateAccount = async (
    values: PersonalAndAccountDetails
  ) => {
    try {
      await validatePersonalDetailsMutation.mutateAsync(values);

      // Create account
      let accountDetails: AccountDetails;
      if (values.accountType === 'organization') {
        accountDetails = {
          accountType: 'organization',
          organizationName: values.organizationName,
          jobTitle: values.jobTitle,
          websiteUrl: values.websiteUrl,
          organizationSize: values.organizationSize,
        };
      } else {
        accountDetails = {
          accountType: 'individual',
        };
      }

      const createAccountPayload = {
        personalDetails: values,
        accountDetails: accountDetails,
      };

      await createAccountMutation.mutateAsync(createAccountPayload);

      identifyUser({ email: values.email });

      // Proceed to the next step
      navigateForward();
    } catch (error) {
      console.error('Error during validation or account creation:', error);
    }
  };

  const { productInstrumentation } = useProductInstrumentation();

  const sendClick = useCallback((target: string) => {
    productInstrumentation?.trackClick({
      target,
      journey: 'sign-up',
      step: 'account-creation',
      variant: '1.1.0',
    });
  }, []);

  const validatePersonalDetailsMutation = useValidatePersonalDetails({
    onSuccess: (next) => {
      productInstrumentation?.trackJourneyStep({
        journey: 'sign-up',
        step: 'account-creation',
        stage: 'completed',
        version: '1.1.0',
      });

      setPersonalAndAccountDetails({ ...personalAndAccountDetails, ...next });
      setBillingInfo({
        ...billingInfo,
        countryCode: next.countryCode,
        paymentMethod: canUseAutoPay(next.countryCode) ? 'autopay' : 'manual',
      });

      const { journey } = signupJourney?.userJourney || {};
      // GA
      const dataLayer = window?.dataLayer || [];
      // legacy event name
      dataLayer.push({
        event: `account_completed`,
      });
      dataLayer.push({
        event: `${journey}_account_completed`,
      });

      // Hubspot
      trackCustomBehavioralEvent(`${journey}_account_completed`, {});
    },
    onError: (error) => {
      productInstrumentation?.trackJourneyStep({
        journey: 'sign-up',
        step: 'account-creation',
        stage: 'errored',
        version: '1.1.0',
      });

      error.details?.forEach((detail) =>
        errorFormatter(detail, personalAndAccountDetailsFormRef.current)
      );
    },
  });

  const createAccountMutation = useCreateAccount<SignupFormData>({
    userJourney: signupJourney.userJourney,
    onSuccess: (next) => {
      if (next.token) {
        setToken(next.token);
      }

      if (next.billingInfo) {
        setBillingInfo(next.billingInfo);
      }

      if (next.userId) {
        setBillingAndRequestCardFormValues({
          ...billingAndRequestCardFormValues,
          accountDetails: next.accountDetails,
          cardHolder: {
            type: 'existing',
            userId: next.userId,
            user: {
              id: next.userId,
              contact: {
                firstName: personalAndAccountDetails.firstName,
                lastName: personalAndAccountDetails.lastName,
                phoneNo: personalAndAccountDetails.phoneNo,
                phoneCountryCode: personalAndAccountDetails.phoneCountryCode,
              },
              address: {
                postalCode: '',
                city: '',
                countryCode: '',
              },
              name:
                personalAndAccountDetails.firstName +
                ' ' +
                personalAndAccountDetails.lastName,
            },
          },
        });
      }

      const countryCode = next.billingInfo?.countryCode || '';
      const currency =
        countries.getCurrencyByAlpha2(countryCode.toUpperCase()) || 'EUR';

      const accountPayload = {
        id: next.accountId || '',
        type: next.accountDetails?.accountType || 'individual',
        billingCurrency: currency,
        billing: {
          accountHolderName: next.billingInfo?.accountHolderName || '',
          ibanNo: next.billingInfo?.ibanNumber || '',
          bicNo: next.billingInfo?.bicNumber || '',
          countryCode: next.billingInfo?.countryCode || '',
          address: next.billingInfo?.street || '',
          city: next.billingInfo?.city || '',
          postalCode: next.billingInfo?.postalCode || '',
          paymentMethod:
            (next.billingInfo?.paymentMethod as 'autopay' | 'manual') ||
            'autopay',
          sepaAccepted: next.billingInfo?.sepaAccepted || false,
        },
        contact: {
          firstName: next.personalDetails.firstName || '',
          lastName: next.personalDetails.lastName || '',
          email: next.personalDetails.email || '',
          phoneNo: next.personalDetails.phoneNo || '',
          phoneCountryCode: next.personalDetails.phoneCountryCode || '',
        },
        chamberOfCommerceNo: next.billingInfo?.chamberOfCommerceNumber,
        vatNo: next.billingInfo?.vatNumber || '',
        hasNoVatNo: !!next.billingInfo?.vatNumber,
        fieldService: {
          email: next.personalDetails.email || '',
        },
        accountTierBillingPlanId: {
          id: signupJourney.userJourney.billingPlanId || '',
        },
      };

      if (next.accountDetails) {
        setAccount(accountPayload);
      }

      navigateForward();
    },
    onError: (error) => {
      error.details?.forEach((detail) => {
        errorFormatter(detail, personalAndAccountDetailsFormRef.current);
      });
    },
  });

  const billingInfoFormRef =
    useRef<FormikProps<BillingAndRequestCardFormValues> | null>(null);

  const validateAndSubmitBillingInfoMutation = useValidateBillingInfo({
    onSuccess: async (next) => {
      if (account.type === 'organization' && next) {
        setAccount((prev) => ({
          ...prev,
          chamberOfCommerceNo: next.chamberOfCommerceNumber || '',
          vatNo: next.vatNumber || '',
          hasNoVatNo: next.vatNotApplicable || false,
        }));
      }

      // update account billing information
      await submitBillingInfoMutation.mutateAsync(next);

      productInstrumentation?.trackJourneyStep({
        journey: 'sign-up',
        step: 'billing-details',
        stage: 'completed',
        version: '1.1.0',
        planId: signupJourney.userJourney.billingPlanId || 'not-set',
      });

      const { journey } = signupJourney?.userJourney || {};
      // GA
      const dataLayer = window?.dataLayer || [];
      // legacy event name
      dataLayer.push({
        event: `billing_completed`,
        accountBillingCountry: account?.billing?.countryCode?.toUpperCase(),
      });
      // new name
      dataLayer.push({
        event: `${journey}_billing_completed`,
        accountBillingCountry: account?.billing?.countryCode?.toUpperCase(),
      });

      // Hubspot
      trackCustomBehavioralEvent(`${journey}_billing_completed`, {});
    },

    onError: (error) => {
      error.details?.forEach((detail) => {
        errorFormatter(detail, billingInfoFormRef.current);
      });

      productInstrumentation?.trackJourneyStep({
        journey: 'sign-up',
        step: 'billing-details',
        stage: 'errored',
        version: '1.1.0',
      });
    },
  });

  const submitBillingInfoMutation = useSubmitBillingInfoMutation({
    account,
    onError: (error) => {
      error.details?.forEach((detail) => {
        errorFormatter(detail, billingInfoFormRef.current);
      });
    },
  });

  const submitCardRequestMutation = useSubmitCardRequest({
    account,
    onSuccess: () => {
      productInstrumentation?.trackJourneyStep({
        journey: 'sign-up',
        stage: 'completed',
        step: 'plan-selection',
        version: '1.1.0',
      });

      setSubmittedCardRequest(true);

      const { journey } = signupJourney?.userJourney || {};
      // GA
      const dataLayer = window?.dataLayer || [];
      dataLayer.push({
        event: `${journey}_plan_completed`,
      });

      // Hubspot
      trackCustomBehavioralEvent(`${journey}_plan_completed`, {});
      navigateForward();
    },
    onError: () => {
      productInstrumentation?.trackJourneyStep({
        journey: 'sign-up',
        stage: 'errored',
        step: 'plan-selection',
        version: '1.1.0',
      });
    },
  });

  const loginMutation = useLogin({
    delay: 500,
    redirectTo: signupJourney.postSignupRedirect,
  });

  const stepToIndexMap = {
    [PersonalDetailsStep]: 0,
    [BillingInfoStep]: 1,
  };

  const [progressStepIndex, setProgressStepIndex] = useState(
    stepToIndexMap[step as keyof typeof stepToIndexMap]
  );
  useEffect(() => {
    if (step === PersonalDetailsStep || step === BillingInfoStep) {
      setProgressStepIndex(stepToIndexMap[step as keyof typeof stepToIndexMap]);
    }
  }, [step]);

  return (
    <div
      className={`${styles.signupPage} ${
        isNotProd() ? styles.withEnvBanner : ''
      }`}
      style={{
        background: headerBackgroundColor || undefined,
      }}>
      <Grid reversed={'mobile'} stackable>
        <Grid.Row style={{ padding: 0 }}>
          <Grid.Column width={4} style={{ padding: 0 }} />
          <Grid.Column
            width={8}
            textAlign={'center'}
            style={{ paddingTop: '50px', paddingBottom: '50px' }}>
            <img
              style={{
                height: '60px',
                width: 'auto',
              }}
              src={logoPath}
            />
          </Grid.Column>
          <Grid.Column
            width={4}
            style={{
              padding: '1rem',
            }}
            textAlign={'right'}>
            <LanguageDropdownV2
              style={{
                backgroundColor: 'transparent',
                minWidth: '6rem',
                margin: '1rem',
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Container style={{ paddingTop: '1rem' }}>
        <Grid centered verticalAlign="middle" style={{ marginBottom: 0 }}>
          <Grid.Column
            style={{ maxWidth: '1400px' }}
            mobile={16}
            tablet={16}
            computer={
              step === BillingInfoStep ? 14 : PersonalDetailsStep ? 8 : 10
            }>
            {/* only show the progress steps if the journey is MSP and only for personal and billing steps*/}
            {signupJourney.userJourney.journey === 'msp' &&
              (step === PersonalDetailsStep || step === BillingInfoStep) && (
                <Segment style={{ margin: 0 }}>
                  <div style={{ maxWidth: '400px', margin: '0 auto' }}>
                    <ProgressSteps
                      steps={[
                        {
                          label: t(
                            'signup.mspHeaderStep1CreateAccount',
                            '1. Create Account'
                          ),
                        },
                        {
                          label: t(
                            'signup.mspHeaderStep2CardTypeBilling',
                            '2. Card Type & Billing'
                          ),
                        },
                      ]}
                      currentStep={progressStepIndex}
                    />
                  </div>
                </Segment>
              )}
            <Grid stackable style={{ marginBottom: 0 }}>
              {step === PersonalDetailsStep && (
                <Panel
                  isLoading={validatePersonalDetailsMutation.isLoading}
                  errorMessage={
                    validatePersonalDetailsMutation.error
                      ? t(
                          'signup.personalDetailsValidationError',
                          'There was a problem verifying your personal information'
                        )
                      : undefined
                  }
                  navigateDirection={navigationDirection}
                  headerText={
                    signupJourney.userJourney.journey === 'msp'
                      ? t(
                          'signup.orderChargeCardHeader',
                          'Order your Charge Card'
                        )
                      : t(
                          'signup.createFreeAccountHeader',
                          'Create your Account'
                        )
                  }
                  subHeaderText={
                    signupJourney.userJourney.journey === 'msp'
                      ? t(
                          'signup.billingDetailsMessage',
                          `2. Fill in your billing details`
                        )
                      : t(
                          'signup.createFreeAccountMessage',
                          'Start your EV charge journey today' // fixme text based on flow (MSP/CPO/Product)
                        )
                  }
                  content={
                    <PersonalDetailsForm
                      formikRef={personalAndAccountDetailsFormRef}
                      value={personalAndAccountDetails}
                      onSubmit={async (next) => {
                        setBillingInfo(
                          billingInfoByAccountType(
                            billingInfo,
                            next.accountType
                          )
                        );

                        setAccount((prev) => ({
                          ...prev,
                          type: next.accountType,
                        }));

                        setPersonalAndAccountDetails(next);

                        await validatePersonalDetailsAndCreateAccount(next);
                      }}
                      showErrorLabels={validatePersonalDetailsMutation.isError}
                    />
                  }
                  belowContent={
                    <Grid.Column>
                      <LoginPanel
                        redirectTo={signupJourney.postSignupRedirect}
                        textAlign="center"
                        text={t(
                          'signup.alreadyHaveAnAccount',
                          'Already have an account?'
                        )}
                      />
                    </Grid.Column>
                  }
                />
              )}

              {step === BillingInfoStep && (
                <Panel
                  step={step}
                  isLoading={
                    validateAndSubmitBillingInfoMutation.isLoading ||
                    createAccountMutation.isLoading
                  }
                  errorMessage={
                    validateAndSubmitBillingInfoMutation.error?.message ||
                    submitCardRequestMutation.error?.message
                  }
                  navigateDirection={navigationDirection}
                  headerText={t(
                    'signup.orderChargeCardHeader',
                    'Order your Charge Card'
                  )}
                  subHeaderText={t(
                    'signup.billingDetailsMessage',
                    `2. Fill in your billing details`
                  )}
                  content={
                    <BillingInfoForm
                      planId={signupJourney.userJourney.billingPlanId || ''}
                      formikRef={billingInfoFormRef}
                      value={billingAndRequestCardFormValues}
                      onSubmit={async (next) => {
                        if (next.cardHolder.type === 'existing') {
                          next.cardHolder.user = {
                            ...next.cardHolder.user,
                            contact: {
                              firstName: personalAndAccountDetails.firstName,
                              lastName: personalAndAccountDetails.lastName,
                              phoneNo: personalAndAccountDetails.phoneNo,
                              phoneCountryCode:
                                personalAndAccountDetails.phoneCountryCode,
                            },
                            address: {
                              postalCode: billingInfo.postalCode,
                              city: billingInfo.city,
                              countryCode: billingInfo.countryCode,
                            },
                          };

                          if (!next.delivery.useSameAsBilling) {
                            next.cardHolder.user = {
                              ...next.cardHolder.user,
                              address: {
                                postalCode: next.delivery.postalCode,
                                city: next.delivery.city,
                                countryCode: next.delivery.countryCode,
                              },
                            };
                          }
                        }

                        // update account billing information
                        await validateAndSubmitBillingInfoMutation.mutateAsync(
                          next.billing
                        );

                        // order charge card
                        submitCardRequestMutation.mutate(next);
                      }}
                      showErrorLabels={
                        validateAndSubmitBillingInfoMutation.isError
                      }
                    />
                  }
                  /* disabling skip card order for now, marketing wants to be 1-1 with old signup to start with
                  belowContent={
                    <Grid.Column width={12}>
                      <div
                        style={{
                          width: '100%',
                          textAlign: 'center',
                        }}>
                        <strong>
                          {t(
                            'signup.skipCardOrder',
                            'Do you want to skip charge card ordering?'
                          )}
                        </strong>
                        <br />
                        <ActionButton
                          primary
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                          onClick={() => {
                            sendClick('finish-account-creation');

                            navigateForward();

                            const dataLayer = window?.dataLayer || [];
                            dataLayer.push({
                              event: `${signupJourney.userJourney.journey}_early_finish`,
                            });

                            // Hubspot
                            trackCustomBehavioralEvent(
                              `${signupJourney.userJourney.journey}_early_finish`,
                              {}
                            );
                          }}>
                          {t(
                            'signup.finishAccountCreation',
                            'Finish account creation'
                          )}
                          <Icon
                            name={'arrow-right'}
                            style={{ marginLeft: '0.5em', marginRight: 0 }}
                          />
                        </ActionButton>
                      </div>
                    </Grid.Column>
                  }
                  */
                />
              )}

              {step === VerifyEmailStep && (
                <Panel
                  navigateDirection={navigationDirection}
                  headerText={t('signup.accountCreated', 'Account created')}
                  subHeaderText={t(
                    'signup.accountCreatedMessage',
                    `You're almost ready to start your EV charge`
                  )}
                  content={
                    <VerifyEmail
                      personalAndAccountDetails={personalAndAccountDetails}
                    />
                  }
                  belowContent={
                    <Grid.Column>
                      <LoginPanel
                        redirectTo={signupJourney.postSignupRedirect}
                        textAlign="center"
                        text={t(
                          'signup.alreadyHaveAnAccount',
                          'Already have an account?'
                        )}
                      />
                    </Grid.Column>
                  }
                />
              )}

              {step === AccountVerifiedStep && (
                <RedirectScreen
                  isLoading={loginMutation.isLoading}
                  error={loginMutation.isError}
                  errorMessage={
                    loginMutation.error
                      ? t(
                          'signup.autoLoginFailed',
                          'There was a problem logging you in automatically.'
                        )
                      : undefined
                  }
                  hideBelowContent={!loginMutation.isError}
                  onSubmit={async () => {
                    loginMutation.mutate(personalAndAccountDetails);

                    const { journey } = signupJourney?.userJourney || {};

                    // this more DX, when you signup again you should get the welcome message
                    window.localStorage.removeItem('dismissWelcomeModal');

                    // GA
                    const dataLayer = window?.dataLayer || [];
                    dataLayer.push({
                      event: `${journey}_signup_completed`,
                    });

                    // Hubspot
                    trackCustomBehavioralEvent(
                      `${journey}_signup_completed`,
                      {}
                    );
                  }}
                  postSignupRedirect={signupJourney.postSignupRedirect}
                  navigationDirection={navigationDirection}
                  variation={getRedirectScreenVariation(
                    signupJourney.userJourney.journey,
                    submittedCardRequest
                  )}
                  loginPanelText={t(
                    'signup.loginManually',
                    'Automatic login not working?'
                  )}
                />
              )}
            </Grid>
          </Grid.Column>
        </Grid>
      </Container>
    </div>
  );
}

function getRedirectScreenVariation(
  journey?: Journey,
  submittedCardRequest?: boolean
): Variation {
  if (journey === 'msp') {
    if (submittedCardRequest) {
      return 'charge-card-ordered';
    }
    return 'account-created';
  }
  return 'account-verified';
}
