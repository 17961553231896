import { useAccountPlatformFeatures } from 'components/account-platform-features/context';
import {
  canAccessAccountEndpoint,
  isSuperAdmin,
  userCanAccessProviderEndpoint,
} from 'utils/roles';
import { User } from 'types/user';
import { Provider } from 'types/provider';

enum PaymentTerminalPlatformFeatures {
  Payter = 'alternative-payment-methods:payter',
  Alpitronic = 'alternative-payment-methods:alpitronic',
  Sicharge = 'alternative-payment-methods:sicharge',
  AdsTec = 'alternative-payment-methods:ads-tec',
  ETotem = 'alternative-payment-methods:e-totem',
  ChargePay = 'alternative-payment-methods:charge-pay',
  MadicLafon = 'alternative-payment-methods:madic-lafon',
}

export const hasPaymentTerminalPlatformFeature = () => {
  const { accountHasPlatformFeature } = useAccountPlatformFeatures();

  return [
    PaymentTerminalPlatformFeatures.Payter,
    PaymentTerminalPlatformFeatures.Alpitronic,
    PaymentTerminalPlatformFeatures.Sicharge,
    PaymentTerminalPlatformFeatures.AdsTec,
    PaymentTerminalPlatformFeatures.ETotem,
    PaymentTerminalPlatformFeatures.ChargePay,
    PaymentTerminalPlatformFeatures.MadicLafon,
  ].some((feature) => accountHasPlatformFeature(feature));
};

export const hasPaymentTerminalPermissions = (
  user: User | undefined,
  requiredLevel: string,
  provider: Provider
) => {
  return (
    user &&
    (isSuperAdmin(user) ||
      userCanAccessProviderEndpoint(
        user,
        provider,
        'paymentTerminals',
        requiredLevel
      ) ||
      (user.accountId &&
        canAccessAccountEndpoint(
          user,
          user.accountId,
          'paymentTerminals',
          requiredLevel
        )))
  );
};
