import { API_URL, SANDBOX_API_URL } from 'utils/env';
import { set } from 'lodash-es';
import { parseToken } from 'utils/token';
import { ApiError, ApiParseError } from './errors';
import { getToken } from './token';
import { emitAuthenticationBusMessage } from 'components/authentication/authentication-bus';

export function joiErrorDetailsToObject(error) {
  const errors = {};
  for (const error of error.details) {
    set(errors, error.context.label, error.message);
  }
  return errors;
}

async function checkTokenExpiration(res, parsedToken) {
  let isTokenExpired = false;

  // early return if token is expired
  // this can be defeated by changing system time but it does save some cycles cloning response below
  if (parsedToken?.exp * 1000 < Date.now()) {
    return true;
  }

  // otherwise check what the API thinks, it may require shorter TTL
  try {
    const responseJson = await res?.clone?.()?.json?.();
    isTokenExpired = responseJson?.error?.details?.some(
      (detail) => detail.code === 'JWT_EXPIRED'
    );
  } catch (e) {}
  return isTokenExpired;
}

export default async function request(options) {
  const {
    method = 'GET',
    path,
    files,
    params,
    providerId,
    returnRaw,
  } = options;
  let { body } = options;

  const token = options.token || getToken();
  const parsed = token && parseToken(token);

  let sandboxMode = false;
  if (token) {
    sandboxMode = parsed?.sandboxMode;
  }

  const headers = Object.assign(
    {
      Accept: 'application/json',
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      // 'API-Key': API_KEY,
    },
    options.headers
  );

  const sessionProviderId = sessionStorage.getItem('resourceProviderId');

  if (providerId || window.provider || sessionProviderId) {
    headers['Provider'] = providerId || sessionProviderId || window.provider.id;
  }

  const apiUrl = sandboxMode ? SANDBOX_API_URL : API_URL;

  const { pathname, origin } = new URL(apiUrl);

  const url = new URL((pathname + path).replace('//', '/'), origin);
  url.search = new URLSearchParams(params);

  if (files) {
    const data = new FormData();
    files.forEach((file) => {
      data.append('file', file);
    });
    for (const [key, value] of Object.entries(body || {})) {
      data.append(key, value);
    }
    body = data;
  } else if (!(body instanceof FormData)) {
    body = JSON.stringify(body);
    headers['Content-Type'] = 'application/json';
  }

  /*
  const res = await fetchWithTimeout(url, {
    method,
    headers,
    body,
  });
  */

  const res = await fetch(url, {
    method,
    headers,
    body,
  });
  if (returnRaw) {
    return res;
  }

  if (res.status === 204) {
    return;
  }

  if (res.status === 401 && !window.location.href.includes('login')) {
    if (parsed?.method === 'sso' && Date(parsed.exp * 1000) < Date.now()) {
      emitAuthenticationBusMessage('sso-token-expired');
    } else if (
      parsed?.method !== 'sso' &&
      (await checkTokenExpiration(res, parsed))
    ) {
      emitAuthenticationBusMessage('token-expired');
      return;
    }
  }

  if (
    [
      'text/csv',
      'application/pdf',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ].includes(res.headers.get('Content-type'))
  ) {
    return res.blob().then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;

      const filename = res.headers
        .get('Content-Disposition')
        ?.split(';')[1]
        .replace('filename=', '')
        .replace(/"/g, '');

      a.download = filename?.trim() || 'export.csv';
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();
      return null;
    });
  }

  if (!res.ok) {
    let type = 'error';
    let message = res.statusText;
    let status = res.status;
    let details;
    try {
      const data = await res.clone().json();
      if (data.error) {
        type = data.error.type;
        message = data.error.message;
        status = data.error.status;
        details = data.error.details;
      }
    } catch (err) {
      message = await res.clone().text();
    }
    throw new ApiError(message, type, status, details);
  }

  try {
    const response = await res.json();
    return response;
  } catch (err) {
    throw new ApiParseError();
  }
}
