import { Layout, Search, SearchFilters } from 'components';
import { Icon, Label, Segment } from 'semantic';
import { request } from 'utils/api';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchFilter from 'components/search/Filters/Search';
import { useFilterContext } from 'components/search/Context';
import Divider from 'components/Sidebar/Divider';
import { FilterMapping } from 'components/search/Provider';
import { DropdownOption } from 'components/search/Filters/DropdownV2';
import { formatDateRangeChip } from 'utils/filters-header';

type UserFilterFields =
  | 'searchPhrase'
  | 'createdAt'
  | 'labels'
  | 'accounts'
  | 'roles';

export const userFilterMapping: FilterMapping<UserFilterFields> = {
  searchPhrase: {
    name: 'searchPhrase',
    type: 'string',
  },
  createdAt: {
    name: 'createdAt',
    type: 'date',
    range: true,
  },
  labels: {
    name: 'labels',
    type: 'base64',
    multiple: true,
    splitBy: '||',
  },
  accounts: {
    name: 'accounts',
    type: 'string',
    multiple: true,
  },
  roles: {
    name: 'roles',
    type: 'string',
    multiple: true,
  },
} as const;

type Account = {
  id: string;
  name: string;
};

type Role = {
  id: string;
  name: string;
  context: string;
};

export const UserFilterHeader = () => {
  const { t } = useTranslation();
  const filterContext = useFilterContext<UserFilterFields>();
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [roleOptions, setRoleOptions] = useState<DropdownOption[]>([]);
  const [isFiltersEmpty, setIsFiltersEmpty] = useState(true);

  const roleContext = (context: string) => {
    if (context === 'provider') {
      return t('common.providerRole', 'Provider Role');
    }
    if (context === 'account') {
      return t('common.accountRole', 'Account Role');
    }
    if (context === 'global') {
      return t('common.globalRole', 'Global Role');
    }
    if (context === 'system') {
      return t('common.systemRole', 'System Role');
    }
    throw new Error(`Unknown role context: ${context}`);
  };

  useEffect(() => {
    request<{ data: Role[] }>({
      path: '/1/roles/search',
      method: 'POST',
    }).then((resp) => {
      const options = resp.data.map((x) => ({
        key: x.id,
        value: x.id,
        text: `${x.name} - ${roleContext(x.context)}`,
      }));
      setRoleOptions(options);
    });
  }, [filterContext.filters]);

  useEffect(() => {
    if (filterContext.filters.accounts) {
      request<{ data: Account[] }>({
        path: '/1/accounts/search',
        method: 'POST',
      }).then((resp) => {
        setAccounts(resp.data);
      });
    }

    setIsFiltersEmpty(Object.keys(filterContext.filters).length === 0);
  }, [filterContext.filters]);

  return (
    <Segment>
      <Layout horizontal spread stackable center>
        <SearchFilters.ModalFilterV2>
          <SearchFilters.DateRange
            label={t('common.createdAt', 'Created at')}
            name={userFilterMapping.createdAt.name}
          />

          <SearchFilters.DropdownSearchFilterV2
            label={t('userFilter.labels', 'Labels')}
            name={userFilterMapping.labels.name}
            multiple={userFilterMapping.labels.multiple}
            populateOnLoad={true}
            onDataNeeded={async () => {
              return await request<{ data: { label: string }[] }>({
                method: 'POST',
                path: '/1/labels/search',
                body: {
                  objectType: 'user',
                },
              }).then((resp) => {
                return {
                  data: resp.data.map((l) => ({
                    id: l.label,
                    name: l.label,
                  })),
                };
              });
            }}
          />

          <SearchFilters.DropdownSearchFilterV2
            label={t('userFilter.accounts', 'Accounts')}
            name={userFilterMapping.accounts.name}
            multiple={true}
            populateOnLoad={true}
            onDataNeeded={async () => {
              return await request<{ data: Account[] }>({
                path: '/1/accounts/search',
                method: 'POST',
              });
            }}
          />

          <SearchFilters.DropdownFilterV2
            label={t('userFilter.Role', 'Role')}
            name={userFilterMapping.roles.name}
            multiple={true}
            options={roleOptions}
          />
        </SearchFilters.ModalFilterV2>

        <Layout horizontal stackable center right>
          <Search.Total />
          <SearchFilter name={userFilterMapping.searchPhrase.name} />
        </Layout>
      </Layout>
      {!isFiltersEmpty && (
        <>
          <Divider style={{ borderTop: '1px solid rgba(34, 36, 38, .15)' }} />
          <Layout horizontal stackable center wrap>
            {filterContext.filters.createdAt && (
              <Label
                key={`${userFilterMapping.createdAt.name}-${filterContext.filters.createdAt}`}
                as="a"
                onClick={() => {
                  filterContext.onFilterChange({
                    name: userFilterMapping.createdAt.name,
                    value: undefined,
                  });
                  filterContext.reload();
                }}>
                {t('common.createdAt', 'Created at')} :
                {formatDateRangeChip(
                  filterContext.filters.createdAt.$gte,
                  filterContext.filters.createdAt.$lte
                )}
                <Icon name="delete" />
              </Label>
            )}

            {filterContext.filters.labels?.map((label: string) => {
              return (
                <Label
                  key={`${userFilterMapping.labels.name}-${label}`}
                  as="a"
                  onClick={() => {
                    filterContext.onFilterChange({
                      name: userFilterMapping.labels.name,
                      value: filterContext.filters.labels?.filter(
                        (labelInContext: string) => labelInContext !== label
                      ),
                    });
                    filterContext.reload();
                  }}>
                  {t('userFilter.labels', 'Labels')} : {label}
                  <Icon name="delete" />
                </Label>
              );
            })}

            {filterContext.filters.accounts?.map((accountId: string) => {
              return (
                <Label
                  key={`${userFilterMapping.accounts.name}-${accountId}`}
                  as="a"
                  onClick={() => {
                    filterContext.onFilterChange({
                      name: userFilterMapping.accounts.name,
                      value: filterContext.filters.accounts?.filter(
                        (x: string) => x !== accountId
                      ),
                    });
                    filterContext.reload();
                  }}>
                  {t('userFilter.account', 'Account')} :{' '}
                  {accounts.find((x) => x.id === accountId)?.name}
                  <Icon name="delete" />
                </Label>
              );
            })}

            {filterContext.filters.roles?.map((roleId: string) => {
              return (
                <Label
                  key={`${userFilterMapping.roles.name}-${roleId}`}
                  as="a"
                  onClick={() => {
                    filterContext.onFilterChange({
                      name: userFilterMapping.roles.name,
                      value: filterContext.filters.roles?.filter(
                        (x: string) => x !== roleId
                      ),
                    });
                    filterContext.reload();
                  }}>
                  {t('userFilter.role', 'Role')} :{' '}
                  {roleOptions.find((x) => x.key === roleId)?.text}
                  <Icon name="delete" />
                </Label>
              );
            })}
          </Layout>
        </>
      )}
    </Segment>
  );
};
