import React, { useContext } from 'react';
import SearchContext from './Context';
import { formatNumber } from 'utils/formatting';
import { useTranslation } from 'react-i18next';

const Total = (props) => {
  const { meta } = useContext(SearchContext);
  const { t } = useTranslation();

  if (!meta) {
    return null;
  }

  return (
    <div style={{ color: '#6C727F', marginRight: '1em' }}>
      {meta?.total ? formatNumber(meta?.total) : 'No'}
      {meta?.total && meta?.approx === 'gte' ? '+' : ''}{' '}
      {t('common.resultFound', 'results found')}
    </div>
  );
};

export default Total;
