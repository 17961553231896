import React from 'react';

import { Progress, Label } from 'semantic';

import { getSetupProgress } from 'utils/evse-controllers';

import { OperationalStatus } from 'components/OperationalStatus';
import { useTranslation } from 'react-i18next';
import { EvseController } from 'types/evse-controller';

export default function EvseStatus({
  item,
  hasOperationalStatus = false,
}: {
  item: EvseController;
  hasOperationalStatus: boolean;
}) {
  const { t } = useTranslation();
  const setupProgress = getSetupProgress(item);

  if (hasOperationalStatus && item.evseOperationalStatus) {
    return (
      <OperationalStatus evseOperationalStatus={item.evseOperationalStatus} />
    );
  }

  if (setupProgress.completed) {
    return (
      <Progress
        percent={100}
        label={t('evseControllerStatus.complete', 'Complete')}
        size="tiny"
      />
    );
  }
  if (setupProgress.readyForActivation) {
    return (
      <Label
        color="blue"
        content={t(
          'evseControllerStatus.activationPending',
          'Activation Pending'
        )}
        title={t(
          'evseControllerStatus.activationPendingTooltip',
          'This EVSE is ready to be activated by the customer or linked by admin'
        )}
      />
    );
  }

  return (
    <Progress
      percent={setupProgress.percent}
      label={t('evseControllerStatus.setupProgress', 'Setup Progress')}
      size="tiny"
    />
  );
}
