import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { Button, Segment, Divider } from 'semantic';

import { currentUserCanAccess } from 'utils/roles';
import ImportUsers from 'components/modals/ImportUsers';
import EditUser from 'components/modals/EditUser';

import Table from './Table';
import { request } from 'utils/api';

import { Breadcrumbs, Search, ListHeader } from 'components';

import { useTranslation } from 'react-i18next';
import { UserFilterHeader, userFilterMapping } from './FilterHeader';

const UsersList = () => {
  const { t } = useTranslation();
  const searchRef = useRef();
  const writeAccess = currentUserCanAccess('users', 'write');

  const onDataNeeded = async (filters) => {
    return request({
      method: 'POST',
      path: `/1/users/search${filters.format !== 'csv' ? '/fast' : ''}`,
      body: filters,
    });
  };

  const onDelete = async (item) => {
    await request({
      method: 'DELETE',
      path: `/1/users/${item.id}`,
    });
    searchRef.current.reload();
  };

  return (
    <Search.Provider
      ref={searchRef}
      onDataNeeded={onDataNeeded}
      filterMapping={userFilterMapping}>
      <React.Fragment>
        <Breadcrumbs
          path={[
            <Link key="accounts" to="/accounts">
              {t('accountDetail.breadcrumbAccounts', 'Accounts')}
            </Link>,
          ]}
          active={t('users.title', 'Users')}
        />

        <ListHeader title={t('users.title', 'Users')}>
          <Search.Export filename="users" />
          <ImportUsers
            onClose={() => searchRef.current.reload()}
            trigger={
              <Button
                basic
                content={t('users.importUser', 'Import Users')}
                icon="upload"
                disabled={!writeAccess}
              />
            }
          />
          <EditUser
            onSave={() => searchRef.current.reload()}
            trigger={
              <Button
                primary
                content={t('users.newUser', 'New User')}
                icon="plus"
                disabled={!writeAccess}
              />
            }
          />
        </ListHeader>

        <UserFilterHeader />

        <Search.Status noResults={t('common.noResults', 'No Results')} />
        <Table onDelete={onDelete} />
        <Divider hidden />
        <div
          style={{
            textAlign: 'center',
          }}>
          <Search.Pagination />
        </div>
      </React.Fragment>
    </Search.Provider>
  );
};

export default UsersList;
