import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import List2 from './List2';
import Detail from './Detail';

import AppWrapper from 'components/AppWrapper';
import { Container } from 'semantic';
import { useFeatures } from 'contexts/features';

export default function Users() {
  const { hasFeature } = useFeatures();
  const multiEnabled = hasFeature('multi_selection_user_filter');

  return (
    <AppWrapper>
      <Container>
        <Switch>
          {!multiEnabled && (
            <Route path="/accounts/users" component={List} exact />
          )}
          {multiEnabled && (
            <Route path="/accounts/users" component={List2} exact />
          )}
          <Route path="/accounts/users/:id" component={Detail} />
        </Switch>
      </Container>
    </AppWrapper>
  );
}
