import AccountPlatformModule from 'components/account-platform-features/AccountPlatformModule';
import { useAccountPlatformFeatures } from 'components/account-platform-features/context';
import SearchDropdown from 'components/form-fields/SearchDropdown';
import { EvseControllerModalTabViewProps } from 'components/modals/EditEvseController/types';
import { useUser } from 'contexts/user';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Icon,
  Message,
  Modal,
  Popup,
  Label,
  Segment,
  DropdownProps,
  InputOnChangeData,
} from 'semantic';
import { request } from 'utils/api';
import { simpleOptions } from 'utils/form';
import { currentProviderIsEflux } from 'utils/providers';
import { canAccess } from 'utils/roles';
import FeatureFlag from '../../../FeatureFlag';
import { TextAreaWithMaxLength } from 'components/form-fields/TextAreaWithMaxLength';
import { Location } from 'types/location';
import { Account } from 'types/account';

export default function EditEvseControllerSettings(
  props: EvseControllerModalTabViewProps
) {
  const { formValues, evseController } = props;
  const [labels, setLabels] = useState<string[]>(formValues.labels || []);
  const { t } = useTranslation();
  const { user, provider } = useUser();

  useEffect(() => {
    props.setField('labels', labels);
  }, [labels]);

  const { accountHasPlatformFeature } = useAccountPlatformFeatures();

  const canEditFieldServiceTechnician = useMemo(() => {
    return (
      user &&
      user?.providerId &&
      user?.accountId &&
      canAccess(
        user,
        user?.providerId,
        user?.accountId,
        'evseControllersFieldServiceTechnicianAccount',
        'write'
      )
    );
  }, [user]);

  const isUpdate = !!props.data.id;

  return (
    <>
      <Form.Input
        value={formValues.name || ''}
        name="name"
        label={t('editEvseControllers.name', 'Name')}
        type="text"
        input={{
          maxlength: 40,
        }}
        onChange={(e, { name, value }) => props.setField(name, value)}
      />
      {isUpdate && (
        <>
          <Form.Input
            value={formValues.numConnectors || ''}
            name="numConnectors"
            label={t(
              'editEvseControllers.numberOfConnectors',
              'Number of Connectors'
            )}
            type="text"
            onChange={(e, { name, value }) => props.setField(name, value)}
          />
        </>
      )}
      {isUpdate && (
        <EvseIdField
          initialValues={props.data}
          formValues={formValues}
          setField={(name, value) => props.setField(name, value)}
        />
      )}
      <Form.Field>
        <label>{t('editEvseControllers.accountLabel', 'Account')}</label>
        <SearchDropdown
          clearable
          objectMode={false}
          value={formValues.accountId}
          onDataNeeded={(body: any) => {
            return request<{ data: Account[] }>({
              path: '/1/accounts/search',
              method: 'POST',
              body: body,
            });
          }}
          onChange={(e: any, { value }: any) => {
            props.setField('accountId', value ?? null);
          }}
        />
      </Form.Field>
      <Form.Field disabled={!formValues.accountId}>
        <label>{t('editEvseControllers.locationLabel', 'Location')}</label>
        <SearchDropdown
          clearable
          value={formValues.locationId}
          objectMode={false}
          onDataNeeded={(body: any) => {
            return request<{ data: Location[] }>({
              path: '/2/locations/cpo/search',
              method: 'POST',
              body: {
                ...body,
                accountId: formValues.accountId,
              },
            });
          }}
          onChange={(e: any, { value }: any) =>
            props.setField('locationId', value)
          }
        />
      </Form.Field>

      <Popup
        disabled={!!formValues.accountId}
        content={t(
          'editEvseControllers.billingPlanDisabled',
          'Billing Plan is available only if the account is set.'
        )}
        position="bottom left"
        trigger={
          <Form.Field>
            <Form.Select
              clearable
              search
              disabled={!formValues.accountId}
              value={formValues.billingPlanId}
              options={props.billingPlans.map((i) => {
                return { value: i.id, text: i.name };
              })}
              name="billingPlanId"
              label={t('editEvseControllers.billingPlanLabel', 'Billing Plan')}
              type="text"
              onChange={(e: any, { name, value }) =>
                props.setField(name, value)
              }
            />
          </Form.Field>
        }
      />
      <Form.Field disabled={!canEditFieldServiceTechnician}>
        <label>
          {t(
            'editEvseControllers.fieldServiceAccountLabel',
            'Field Service Account'
          )}
        </label>
        <SearchDropdown
          clearable
          value={formValues.maintenanceAccountId}
          objectMode={false}
          onDataNeeded={(body: any) => {
            return request({
              path: '/1/accounts/search',
              method: 'POST',
              body: {
                ...body,
                includeParentProviderAccounts: true,
              },
            });
          }}
          onChange={(e: any, { value }: any) =>
            props.setField('maintenanceAccountId', value)
          }
        />
      </Form.Field>
      <TextAreaWithMaxLength
        value={formValues.description || ''}
        name="description"
        label={t('editEvseControllers.description', 'Description')}
        maxLength={500}
        charactersRemainingDescription={(charactersRemaining: number) =>
          t(
            'editEvseControllers.charactersRemaining',
            '{{charactersRemaining}} characters remaining',
            {
              charactersRemaining,
            }
          )
        }
        onChange={(e: any, { name, value }) =>
          // if value is empty string, just omit it
          props.setField(name, value ? value : undefined)
        }
      />
      {accountHasPlatformFeature('charging-station-ui:scan-to-pay') && (
        <Form.Checkbox
          label={t(
            'editEvseControllers.optOutOfScanToPay',
            'Opt-out of "Scan to pay" functionality'
          )}
          name="optOutOfScanToPay"
          checked={formValues.optOutOfScanToPay}
          onChange={(e, { name, checked }) => props.setField(name!, checked)}
        />
      )}
      <br />
      <Form.Field style={{ marginBottom: 6 }}>
        <label>{t('editEvseControllers.roamingLabel', 'Roaming')}</label>
      </Form.Field>
      <Form.Checkbox
        label={t(
          'editEvseControllers.enablePublicChargingLabel',
          'Allow charging from other networks (roaming)'
        )}
        name="enablePublicCharging"
        checked={formValues.enablePublicCharging}
        disabled={formValues.enablePublicFreeCharging}
        onChange={(e, { name, checked }) => props.setField(name!, checked)}
      />
      {currentProviderIsEflux() && (
        <React.Fragment>
          <Form.Checkbox
            label={t(
              'editEvseControllers.disableRoamingChargeForProvidersLabel',
              'Disable roaming surcharge per kwh for certain providers'
            )}
            name="disableRoamingChargeForProviders"
            checked={formValues.disableRoamingChargeForProviders}
            disabled={
              formValues.enablePublicFreeCharging ||
              !formValues.enablePublicCharging
            }
            onChange={(e, { name, checked }) => props.setField(name!, checked)}
          />

          {formValues.disableRoamingChargeForProviders && (
            <Form.Dropdown
              search
              selection
              multiple
              allowAdditions
              name="disableRoamingChargePartyIds"
              label={t(
                'editEvseControllers.disableRoamingChargePartyIdsLabel',
                'Disable roaming surcharge for the following party IDs (e.g. NLEVB)'
              )}
              options={
                formValues.disableRoamingChargePartyIds?.map((value) => ({
                  key: value,
                  value,
                  text: value,
                })) || []
              }
              onChange={(e: SyntheticEvent, { name, value }: DropdownProps) => {
                if (Array.isArray(value)) {
                  props.setField(
                    name,
                    value?.map((v) => `${v}`.toUpperCase())
                  );
                }
              }}
              value={formValues.disableRoamingChargePartyIds || []}
            />
          )}
        </React.Fragment>
      )}
      {provider?.enableChargeStationPairChargeCard &&
        props.evseController?.isPairingChargeCardAllowed && (
          <Form.Checkbox
            label={t(
              'editEvseControllers.pairedChargeCard',
              'Pair a charge card that will be used to invoice all sessions started on this charging station'
            )}
            name="pairedChargeCard"
            checked={!!formValues.pairedChargeCard}
            disabled={
              formValues.enablePublicFreeCharging ||
              !formValues.enablePublicCharging
            }
            onChange={(e: any, { name, checked }) => {
              if (checked) {
                props.setField(name!, evseController?.pairedChargeCard || {});
                props.setField(
                  'pairedChargeCardId',
                  evseController?.pairedChargeCardId
                );
              } else {
                props.setField(name!, null);
                props.setField('pairedChargeCardId', null);
              }
            }}
          />
        )}
      {formValues.pairedChargeCard && (
        <Segment>
          <Message
            content={t(
              'editEvseControllers.pairedChargeCardNote',
              'Note: The charge card will only be paired when the station is connected. You can check the pairing status on the Charging Station page > Overview > Details.'
            )}
          />
          <Form.Field>
            <SearchDropdown
              clearable
              objectMode={false}
              label={t('editEvseControllers.accountLabel', 'Account')}
              value={
                formValues.pairedChargeCard?.accountId || props.data?.accountId
              }
              onDataNeeded={(body: any) => {
                return request({
                  path: '/1/accounts/search',
                  method: 'POST',
                  body: {
                    ...body,
                    includeParentProviderAccounts: true,
                  },
                });
              }}
              onChange={(e: any, { value }: any) => {
                props.setNestedField('pairedChargeCard')(
                  'accountId',
                  value ?? null
                );
                props.setField('pairedChargeCardId', null);
              }}
            />
          </Form.Field>
          <Form.Field>
            <SearchDropdown
              required
              objectMode={false}
              value={formValues.pairedChargeCardId}
              placeholder={t(
                'editEvseControllers.searchChargeCardLabel',
                'Select User / Card Identifier'
              )}
              label={t('editEvseControllers.chargeCardLabel', 'Charge Card')}
              getOptionLabel={(item: {
                user: { name: string };
                token: { visualNumber?: string; customId?: string };
              }) =>
                `${item.user.name} - ${
                  item.token?.visualNumber || item.token?.customId
                }`
              }
              forceFetchOnFocus
              onDataNeeded={(body: any) => {
                return request({
                  path: '/1/cards/search/fast',
                  method: 'POST',
                  body: {
                    accountId:
                      formValues.pairedChargeCard?.accountId ||
                      props.data?.accountId,
                    status: ['active', 'sent'],
                    searchPhrase: body.name,
                    limit: 10000, // just to return all results at once
                  },
                });
              }}
              onChange={(e: any, { value }: any) => {
                props.setField('pairedChargeCardId', value ?? null);
              }}
            />
          </Form.Field>
        </Segment>
      )}
      <Form.Checkbox
        label={t(
          'editEvseControllers.enablePublicFreeChargingLabel',
          'Allow any card to charge here for free (ignores all cost settings)'
        )}
        name="enablePublicFreeCharging"
        checked={formValues.enablePublicFreeCharging}
        onChange={(e: any, { name, checked }) => props.setField(name!, checked)}
      />
      <FeatureFlag feature="operational_statuses_page" invert>
        <>
          <br />
          <Form.Checkbox
            label={t(
              'editEvseControllers.isDisabledLabel',
              'Disable this charge point (no charging, no billing)'
            )}
            name="isDisabled"
            toggle
            checked={formValues.isDisabled}
            onChange={(e: any, { name, checked }) =>
              props.setField(name!, checked)
            }
          />
        </>
      </FeatureFlag>
      <FeatureFlag feature="evsecontroller-labels">
        <div>
          <h5>{t('editEvseControllers.labels', 'Labels')}</h5>
          <p>
            {t(
              'editEvseControllers.labelsInfo',
              'Labels will help you to group any charging stations of your choice and to easily filter all charging stations with the same label.'
            )}
          </p>
          <Segment style={{ marginBottom: '1rem' }}>
            <SearchDropdown
              label={t('editEvseControllers.assignLabels', 'Assign Labels')}
              objectMode={false}
              allowAdditions={true}
              value={''}
              onChange={(e: any, { value }: any) => {
                e.preventDefault();
                if (!labels.includes(value)) {
                  setLabels([...labels, value]);
                }
              }}
              onDataNeeded={() =>
                request({
                  path: '/1/evse-controllers/available-labels',
                  method: 'GET',
                }).then((res) => {
                  return {
                    data: res.data?.map((label: string) => ({
                      id: label,
                      name: label,
                    })),
                  };
                })
              }
            />

            {labels.length > 0 && (
              <Form.Field>
                <label>
                  {t(
                    'editEvseControllers.existingLabels',
                    'Detected assigned Labels'
                  )}
                </label>
                {labels.map((label) => (
                  <Label
                    key={label}
                    as="a"
                    onClick={(e) => {
                      e.preventDefault();
                      setLabels(labels.filter((l) => l !== label));
                    }}>
                    {label}
                    <Icon name="delete" />
                  </Label>
                ))}
              </Form.Field>
            )}
          </Segment>
        </div>
      </FeatureFlag>
      {formValues.accountId && (
        <Form.Field>
          <label>
            {t('editEvseControllers.accessGroupsLabel', 'Access Groups')}
          </label>
          <SearchDropdown
            disabled={formValues.enablePublicFreeCharging}
            multiple
            value={formValues.accessGroupIds}
            objectMode={false}
            onDataNeeded={(body: any) => {
              return request({
                path: '/1/access-groups/search',
                method: 'POST',
                body: {
                  ...body,
                  accountId: formValues.accountId,
                },
              });
            }}
            onChange={(e: any, { value }: any) => {
              props.setField('accessGroupIds', value);
            }}
          />
        </Form.Field>
      )}
      <AccountPlatformModule moduleName={'smart-charging'}>
        <SmartChargingField
          value={formValues.smartCharging?.method}
          onChange={(e: any, { name, value }) => {
            props.setNestedField('smartCharging')(name, value, () => {
              if (value === 'none') {
                props.setNestedField('smartCharging')('pairingCode', null);
              }
            });
          }}
        />
        {formValues.smartCharging?.method === 'stekker' && (
          <SmartChargingPairingCodeField
            value={formValues.smartCharging?.pairingCode}
            onChange={(e: any, { name, value }) =>
              props.setNestedField('smartCharging')(name, value)
            }
          />
        )}
      </AccountPlatformModule>
    </>
  );
}

type EvseIdFieldProps = {
  initialValues: any;
  formValues: any;
  setField: (name: string, value: any) => void;
};

function EvseIdField({
  initialValues,
  formValues,
  setField,
}: EvseIdFieldProps) {
  const { t } = useTranslation();
  const [showInput, setShowInput] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [currentEvseId, setCurrentEvseId] = useState(initialValues.evseId);
  const [saved, setSaved] = useState(false);
  const [inputReadOnly, setInputReadOnly] = useState(true);

  const generateNewEvseId = async () => {
    setGenerating(true);
    const { data: updatedEvseController } = await request({
      method: 'POST',
      path: `/1/evse-controllers/assign-new-evseid`,
      body: {
        evseControllerId: formValues.id,
      },
    });
    setGenerating(false);
    setField(
      'evseId',
      updatedEvseController.evseId ||
        updatedEvseController.evseIdRef?.identifier
    );
    setCurrentEvseId(updatedEvseController.evseId);
    setSaved(true);
  };

  if (showInput) {
    return (
      <Form.Group>
        <Form.Input
          value={formValues.evseId}
          name="evseId"
          label={t('editEvseControllers.evseIdLabel', 'EVSE ID')}
          type="text"
          width={7}
          onChange={(e, { name, value }) => setField(name, value)}
        />
        <Form.Button
          label="&nbsp;"
          content={t('editEvseControllers.revertEvseIdChangeLabel', 'Revert')}
          onClick={(e) => {
            setField('evseId', currentEvseId);
            e.stopPropagation();
            e.preventDefault();
            setSaved(false);
            setInputReadOnly(true);
            setShowInput(false);
          }}
        />
      </Form.Group>
    );
  }

  return (
    <>
      <Form.Group>
        <Form.Input
          value={currentEvseId}
          name="evseId"
          label={t('editEvseControllers.evseIdLabel', 'EVSE ID')}
          type="text"
          disabled={inputReadOnly}
          width={7}
        />
        {!showInput && (
          <>
            <Form.Button
              label="&nbsp;"
              content={t(
                'editEvseControllers.enterEvseIdSticker',
                'Enter EVSE ID from sticker'
              )}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setField('evseId', '');
                setSaved(false);
                setInputReadOnly(false);
                setShowInput(true);
              }}
            />

            <Modal
              header={t(
                'editEvseControllers.confirmNewEvseId',
                'Are you sure you want to generate new EVSE ID for this controller?'
              )}
              content={t(
                'editEvseControllers.newEvseIdConfirmInfo',
                "A new identifier will be generated and assigned to this EVSE. If there's an existing sticker on the station it will no longer be valid."
              )}
              trigger={
                <Form.Button
                  label="&nbsp;"
                  disabled={generating}
                  content={t(
                    'editEvseControllers.generateNewEvseIdAction',
                    'Generate new EVSE ID'
                  )}
                  onClick={async (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setSaved(false);
                  }}
                />
              }
              closeIcon
              actions={[
                {
                  key: 'new-evse-id',
                  primary: true,
                  content: t(
                    'editEvseControllers.generateNewEvseIdAction',
                    'Generate new EVSE ID'
                  ),
                  onClick: async () => {
                    await generateNewEvseId();
                  },
                },
              ]}
            />
          </>
        )}
      </Form.Group>
      {saved && (
        <Message
          success
          content={t(
            'editEvseControllers.generateNewEvseIdSuccess',
            'New EVSE ID has been assigned.'
          )}
        />
      )}
    </>
  );
}

type SmartChargingFieldProps = {
  value?: string;
  onChange: (
    event: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps
  ) => void;
};
function SmartChargingField({ value, onChange }: SmartChargingFieldProps) {
  const SMART_CHARGING_METHODS = {
    NONE: 'none',
    STEKKER: 'stekker',
  } as const;

  type SmartChargingMethod =
    (typeof SMART_CHARGING_METHODS)[keyof typeof SMART_CHARGING_METHODS];

  const { t } = useTranslation();
  const options: SmartChargingMethod[] = [SMART_CHARGING_METHODS.NONE];
  const { accountHasPlatformFeature } = useAccountPlatformFeatures();
  if (accountHasPlatformFeature('smart-charging:stekker')) {
    options.push(SMART_CHARGING_METHODS.STEKKER);
  }
  return (
    <Form.Field>
      <label>{t('smartCharging.method', 'Smart Charging Method')}</label>
      <Form.Dropdown
        value={value}
        selection
        options={simpleOptions(options)}
        id="smartCharging.method"
        name="method"
        onChange={onChange}
      />
    </Form.Field>
  );
}

type SmartChargingPairingCodeFieldProps = {
  value?: string | undefined;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => void;
};
function SmartChargingPairingCodeField({
  value,
  onChange,
}: SmartChargingPairingCodeFieldProps) {
  const { t } = useTranslation();
  const { accountHasPlatformFeature } = useAccountPlatformFeatures();
  const stekkerPairingEnabled = accountHasPlatformFeature(
    'smart-charging:stekker'
  );
  return (
    <Form.Field disabled={!stekkerPairingEnabled}>
      <Form.Input
        value={value || ''}
        id="smartCharging.pairingCode"
        name="pairingCode"
        label={t('smartCharging.pairingCode', 'Smart Charging Pairing Code')}
        type="text"
        onChange={onChange}
      />
    </Form.Field>
  );
}
