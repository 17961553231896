import { Layout, Search, SearchFilters } from 'components';
import { Icon, Label, Segment } from 'semantic';
import { request } from 'utils/api';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchFilter from 'components/search/Filters/Search';
import { useFilterContext } from 'components/search/Context';
import Divider from 'components/Sidebar/Divider';
import { FilterMapping } from 'components/search/Provider';
import { DropdownOption } from 'components/search/Filters/DropdownV2';
import { formatDateRangeChip } from 'utils/filters-header';

type AccountFilterFields =
  | 'searchPhrase'
  | 'createdAt'
  | 'labels'
  | 'type'
  | 'accountTiers'
  | 'billingPlans'
  | 'currencies'
  | 'countries'
  | 'trustTiers'
  | 'platformFeatures';

export const accountFilterMapping: FilterMapping<AccountFilterFields> = {
  searchPhrase: {
    name: 'searchPhrase',
    type: 'string',
  },
  createdAt: {
    name: 'createdAt',
    type: 'date',
    range: true,
  },
  labels: {
    name: 'labels',
    type: 'base64',
    multiple: true,
    splitBy: '||',
  },
  type: {
    name: 'type',
    type: 'string',
  },
  accountTiers: {
    name: 'accountTiers',
    type: 'string',
    multiple: true,
  },
  billingPlans: {
    name: 'billingPlans',
    type: 'string',
    multiple: true,
  },
  currencies: {
    name: 'currencies',
    type: 'string',
    multiple: true,
  },
  countries: {
    name: 'countries',
    type: 'string',
    multiple: true,
  },
  trustTiers: {
    name: 'trustTiers',
    type: 'number',
    multiple: true,
  },
  platformFeatures: {
    name: 'platformFeatures',
    type: 'string',
    multiple: true,
  },
} as const;

type Dto = {
  id: string;
  name: string;
};

type PlatformModule = {
  key: string;
  name: string;
  features: PlatformFeature[];
};

type PlatformFeature = {
  key: string;
  name: string;
};

export const AccountFilterHeader = () => {
  const { t } = useTranslation();
  const filterContext = useFilterContext<AccountFilterFields>();
  const [accountTiers, setAccountTiers] = useState<Dto[]>([]);
  const [billingPlans, setBillingPlans] = useState<Dto[]>([]);
  const [platformFeatures, setPlatformFeatures] = useState<PlatformFeature[]>(
    []
  );
  const [isFiltersEmpty, setIsFiltersEmpty] = useState(true);

  const typeOptions: DropdownOption[] = [
    {
      key: 'organization',
      value: 'organization',
      text: t('accountFilter.organization', 'Organization'),
    },
    {
      key: 'individual',
      value: 'individual',
      text: t('accountFilter.individual', 'Individual'),
    },
  ];

  const currencyOptions: DropdownOption[] = [
    {
      key: 'CHF',
      value: 'CHF',
      text: 'CHF',
    },
    {
      key: 'GBP',
      value: 'GBP',
      text: 'GBP',
    },
    {
      key: 'EUR',
      value: 'EUR',
      text: 'EUR',
    },
  ];

  const countryOptions: DropdownOption[] = [
    {
      key: 'NL',
      value: 'NL',
      text: t('common.netherlands', 'Netherlands'),
    },
    {
      key: 'BE',
      value: 'BE',
      text: t('common.belgium', 'Belgium'),
    },
    {
      key: 'DE',
      value: 'DE',
      text: t('common.germany', 'Germany'),
    },
    {
      key: 'FR',
      value: 'FR',
      text: t('common.france', 'France'),
    },
    {
      key: 'AT',
      value: 'AT',
      text: t('common.austria', 'Austria'),
    },
    {
      key: 'CH',
      value: 'CH',
      text: t('common.switzerland', 'Switzerland'),
    },
    {
      key: 'IT',
      value: 'IT',
      text: t('common.italy', 'Italy'),
    },
    {
      key: 'ES',
      value: 'ES',
      text: t('common.spain', 'Spain'),
    },
    {
      key: 'GB',
      value: 'GB',
      text: t('common.unitedKingdom', 'United Kingdom'),
    },
  ];

  const trustTierOptions: DropdownOption[] = [
    {
      key: '0',
      value: '0',
      text: t('accountFilter.tier0', 'Tier 0'),
    },
    {
      key: '1',
      value: '1',
      text: t('accountFilter.tier1', 'Tier 1'),
    },
    {
      key: '2',
      value: '2',
      text: t('accountFilter.tier2', 'Tier 2'),
    },
    {
      key: '3',
      value: '3',
      text: t('accountFilter.tier3', 'Tier 3'),
    },
    {
      key: '4',
      value: '4',
      text: t('accountFilter.tier4', 'Tier 4'),
    },
    {
      key: '5',
      value: '5',
      text: t('accountFilter.tier5', 'Tier 5'),
    },
    {
      key: '6',
      value: '6',
      text: t('accountFilter.tier6', 'Tier 6'),
    },
  ];

  useEffect(() => {
    if (filterContext.filters.accountTiers) {
      request<{ data: Dto[] }>({
        path: '/1/account-tiers/',
        method: 'GET',
      }).then((resp) => {
        setAccountTiers(resp.data);
      });
    }

    if (filterContext.filters.billingPlans) {
      request<{ data: Dto[] }>({
        path: '/1/billing-plans/search',
        method: 'POST',
        body: {
          type: 'account-tier',
        },
      }).then((resp) => {
        setBillingPlans(resp.data);
      });
    }

    if (filterContext.filters.platformFeatures) {
      request<{ data: PlatformModule[] }>({
        path: '/1/platform-modules',
        method: 'GET',
      }).then((resp) => {
        const features: PlatformFeature[] = [];
        resp.data.forEach((module) => {
          features.push(...module.features);
        });
        setPlatformFeatures(features);
      });
    }

    setIsFiltersEmpty(Object.keys(filterContext.filters).length === 0);
  }, [filterContext.filters]);

  return (
    <Segment>
      <Layout horizontal spread stackable center>
        <SearchFilters.ModalFilterV2>
          <SearchFilters.DateRange
            label={t('common.createdAt', 'Created at')}
            name={accountFilterMapping.createdAt.name}
          />

          <SearchFilters.DropdownSearchFilterV2
            label={t('accountFilter.labels', 'Labels')}
            name={accountFilterMapping.labels.name}
            multiple={accountFilterMapping.labels.multiple}
            populateOnLoad={true}
            onDataNeeded={async () => {
              return await request<{ data: { label: string }[] }>({
                method: 'POST',
                path: '/1/labels/search',
                body: {
                  objectType: 'account',
                },
              }).then((resp) => {
                return {
                  data: resp.data.map((l) => ({
                    id: l.label,
                    name: l.label,
                  })),
                };
              });
            }}
          />

          <SearchFilters.DropdownFilterV2
            label={t('accountFilter.accountType', 'Account Type')}
            name={accountFilterMapping.type.name}
            options={typeOptions}
          />

          <SearchFilters.DropdownSearchFilterV2
            label={t('accountFilter.accountTier', 'Account Tier')}
            name={accountFilterMapping.accountTiers.name}
            multiple={true}
            populateOnLoad={true}
            onDataNeeded={async () => {
              return await request<{ data: Dto[] }>({
                method: 'GET',
                path: '/1/account-tiers',
              });
            }}
          />

          <SearchFilters.DropdownSearchFilterV2
            label={t(
              'accountFilter.accountTierBillingPlan',
              'Account Tier Billing Plan'
            )}
            name={accountFilterMapping.billingPlans.name}
            multiple={true}
            populateOnLoad={true}
            onDataNeeded={async () => {
              return await request<{ data: Dto[] }>({
                path: '/1/billing-plans/search',
                method: 'POST',
                body: {
                  type: 'account-tier',
                },
              });
            }}
          />

          <SearchFilters.DropdownFilterV2
            label={t('accountFilter.billingCurrency', 'Billing Currency')}
            name={accountFilterMapping.currencies.name}
            multiple={true}
            options={currencyOptions}
          />

          <SearchFilters.DropdownFilterV2
            label={t('accountFilter.billingCountry', 'Billing Country')}
            name={accountFilterMapping.countries.name}
            multiple={true}
            options={countryOptions}
          />

          <SearchFilters.DropdownFilterV2
            label={t('accountFilter.trustTier', 'Trust Tier')}
            name={accountFilterMapping.trustTiers.name}
            multiple={true}
            options={trustTierOptions}
          />

          <SearchFilters.DropdownSearchFilterV2
            label={t('accountFilter.platformFeatures', 'Platform Features')}
            name={accountFilterMapping.platformFeatures.name}
            multiple={true}
            populateOnLoad={true}
            onDataNeeded={async () => {
              const resp = await request<{ data: PlatformModule[] }>({
                path: '/1/platform-modules',
                method: 'GET',
              });
              const features: PlatformFeature[] = [];
              resp.data.forEach((module) => {
                features.push(...module.features);
              });
              const data = features.map((x) => ({
                id: x.key,
                name: x.name,
              }));
              return { data };
            }}
          />
        </SearchFilters.ModalFilterV2>

        <Layout horizontal stackable center right>
          <Search.Total />
          <SearchFilter name={accountFilterMapping.searchPhrase.name} />
        </Layout>
      </Layout>
      {!isFiltersEmpty && (
        <>
          <Divider style={{ borderTop: '1px solid rgba(34, 36, 38, .15)' }} />
          <Layout horizontal stackable center wrap>
            {filterContext.filters.createdAt && (
              <Label
                key={`${accountFilterMapping.createdAt.name}-${filterContext.filters.createdAt}`}
                as="a"
                onClick={() => {
                  filterContext.onFilterChange({
                    name: accountFilterMapping.createdAt.name,
                    value: undefined,
                  });
                  filterContext.reload();
                }}>
                {t('common.createdAt', 'Created at')} :
                {formatDateRangeChip(
                  filterContext.filters.createdAt.$gte,
                  filterContext.filters.createdAt.$lte
                )}
                <Icon name="delete" />
              </Label>
            )}

            {filterContext.filters.labels?.map((label: string) => {
              return (
                <Label
                  key={`${accountFilterMapping.labels.name}-${label}`}
                  as="a"
                  onClick={() => {
                    filterContext.onFilterChange({
                      name: accountFilterMapping.labels.name,
                      value: filterContext.filters.labels?.filter(
                        (labelInContext: string) => labelInContext !== label
                      ),
                    });
                    filterContext.reload();
                  }}>
                  {t('accountFilter.labels', 'Labels')} : {label}
                  <Icon name="delete" />
                </Label>
              );
            })}

            {filterContext.filters.type && (
              <Label
                key={`${accountFilterMapping.type.name}-${filterContext.filters.type}`}
                as="a"
                onClick={() => {
                  filterContext.onFilterChange({
                    name: accountFilterMapping.type.name,
                    value: undefined,
                  });
                  filterContext.reload();
                }}>
                {t('accountFilter.accountType', 'Account Type')} :{' '}
                {
                  typeOptions.find(
                    (x) => x.value === filterContext.filters.type
                  )?.text
                }
                <Icon name="delete" />
              </Label>
            )}

            {filterContext.filters.accountTiers?.map(
              (accountTierId: string) => {
                return (
                  <Label
                    key={`${accountFilterMapping.accountTiers.name}-${accountTierId}`}
                    as="a"
                    onClick={() => {
                      filterContext.onFilterChange({
                        name: accountFilterMapping.accountTiers.name,
                        value: filterContext.filters.accountTiers?.filter(
                          (x: string) => x !== accountTierId
                        ),
                      });
                      filterContext.reload();
                    }}>
                    {t('accountFilter.accountTier', 'Account Tier')} :{' '}
                    {accountTiers.find((x) => x.id === accountTierId)?.name}
                    <Icon name="delete" />
                  </Label>
                );
              }
            )}

            {filterContext.filters.billingPlans?.map(
              (billingPlanId: string) => {
                return (
                  <Label
                    key={`${accountFilterMapping.billingPlans.name}-${billingPlanId}`}
                    as="a"
                    onClick={() => {
                      filterContext.onFilterChange({
                        name: accountFilterMapping.billingPlans.name,
                        value: filterContext.filters.billingPlans?.filter(
                          (x: string) => x !== billingPlanId
                        ),
                      });
                      filterContext.reload();
                    }}>
                    {t(
                      'accountFilter.accountTierBillingPlan',
                      'Account Tier Billing Plan'
                    )}{' '}
                    : {billingPlans.find((x) => x.id === billingPlanId)?.name}
                    <Icon name="delete" />
                  </Label>
                );
              }
            )}

            {filterContext.filters.currencies?.map((currency: string) => {
              return (
                <Label
                  key={`${accountFilterMapping.currencies.name}-${currency}`}
                  as="a"
                  onClick={() => {
                    filterContext.onFilterChange({
                      name: accountFilterMapping.currencies.name,
                      value: filterContext.filters.currencies?.filter(
                        (x: string) => x !== currency
                      ),
                    });
                    filterContext.reload();
                  }}>
                  {t('accountFilter.billingCurrency', 'Billing Currency')} :{' '}
                  {currency}
                  <Icon name="delete" />
                </Label>
              );
            })}

            {filterContext.filters.countries?.map((country: string) => {
              return (
                <Label
                  key={`${accountFilterMapping.countries.name}-${country}`}
                  as="a"
                  onClick={() => {
                    filterContext.onFilterChange({
                      name: accountFilterMapping.countries.name,
                      value: filterContext.filters.countries?.filter(
                        (x: string) => x !== country
                      ),
                    });
                    filterContext.reload();
                  }}>
                  {t('accountFilter.billingCountry', 'Billing Country')} :{' '}
                  {country}
                  <Icon name="delete" />
                </Label>
              );
            })}

            {filterContext.filters.trustTiers?.map((trustTier: string) => {
              return (
                <Label
                  key={`${accountFilterMapping.trustTiers.name}-${trustTier}`}
                  as="a"
                  onClick={() => {
                    filterContext.onFilterChange({
                      name: accountFilterMapping.trustTiers.name,
                      value: filterContext.filters.trustTiers?.filter(
                        (x: string) => x !== trustTier
                      ),
                    });
                    filterContext.reload();
                  }}>
                  {t('accountFilter.trustTier', 'Trust Tier')} : {trustTier}
                  <Icon name="delete" />
                </Label>
              );
            })}

            {filterContext.filters.platformFeatures?.map((feature: string) => {
              return (
                <Label
                  key={`${accountFilterMapping.platformFeatures.name}-${feature}`}
                  as="a"
                  onClick={() => {
                    filterContext.onFilterChange({
                      name: accountFilterMapping.platformFeatures.name,
                      value: filterContext.filters.platformFeatures?.filter(
                        (x: string) => x !== feature
                      ),
                    });
                    filterContext.reload();
                  }}>
                  {t('accountFilter.platformFeature', 'Platform Feature')} :{' '}
                  {platformFeatures.find((x) => x.key === feature)?.name}
                  <Icon name="delete" />
                </Label>
              );
            })}
          </Layout>
        </>
      )}
    </Segment>
  );
};
