import { usePlatformModules } from 'contexts/platformModules';
import { canAccess, isSuperAdmin } from 'utils/roles';
import { useUser } from 'contexts/user';
import { FeatureFlags, useFeatures } from 'contexts/features';

const REQUIRED_FEATURES = [
  'smart-charging:kia',
  'smart-charging:coneva',
  'smart-charging:pluq',
  'smart-charging:scoptvision',
];
const REQUIRED_PERMISSION = 'evseControllersIntegrations';

const useUserHasAccessToEvseControllersIntegrations = (
  permission: 'read' | 'read-write' | 'none'
): boolean => {
  const { user, provider, hasPlatformFeature } = useUser();
  const { hasPlatformModule } = usePlatformModules();
  const { hasFeature } = useFeatures();

  // super admins have access to everything
  if (isSuperAdmin(user)) {
    return true;
  }

  const useCsmePlatformModule = hasFeature(FeatureFlags.CsmePlatformModule);

  let providerEnabled;
  let hasIntegrationsAccess;
  if (useCsmePlatformModule) {
    providerEnabled = (provider?.enabledCsmeClientIds || []).length > 0;
    hasIntegrationsAccess = (user?.csmeClientIds || []).length > 0;
  } else {
    providerEnabled = hasPlatformModule('smart-charging');
    // needs to have at least one of the required features
    hasIntegrationsAccess = REQUIRED_FEATURES.some((feature) =>
      hasPlatformFeature(feature)
    );
  }

  const canAccessEvseControllersIntegrations =
    permission === 'none' ||
    canAccess(
      user,
      user.providerId,
      user.accountId,
      REQUIRED_PERMISSION,
      permission
    );

  return (
    providerEnabled &&
    hasIntegrationsAccess &&
    canAccessEvseControllersIntegrations
  );
};

export default useUserHasAccessToEvseControllersIntegrations;
