import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import {
  Loader,
  TableCell,
  TableRow,
  Table,
  TableBody,
} from 'semantic-ui-react';
import { Button, Icon } from 'semantic';
import { isEmpty, isNumber } from 'lodash-es';
import { Link } from 'react-router-dom';

import styles from './DataImportFileRow.module.less';
import { dataTypeTitle, filterUrlByType } from 'screens/DataImports/utils';
import { StatusLabel } from '../StatusLabel';
import { DataImport, DataImportFile } from 'types/data-import';
import { formatAPIError } from 'utils/error';
import Pagination from 'components/Pagination';

const PAGINATION_SIZE = 10;

type DataImportFileValidatedRowProps = {
  dataImport: DataImport;
  file: DataImportFile;
  errors?: Error[] | null;
  totalErrors?: number;
  onErrorsPageChange: (limit: number, skip: number) => void;
  onExportErrors?: () => void;
  total?: number;
  imported?: number;
  failed?: number;
  showStatus?: boolean;
  showReadyForImport?: boolean;
  icon?: 'loading' | 'warning' | 'success' | 'error';
  collapseDisabled?: boolean;
  onDelete?: () => void;
  onOpen?: () => void;
};

export default function DataImportFileValidatedRow({
  dataImport,
  file,
  errors,
  total,
  imported,
  failed,
  showReadyForImport,
  icon,
  collapseDisabled,
  totalErrors,
  onExportErrors,
  onErrorsPageChange,
  onOpen,
  onDelete,
}: DataImportFileValidatedRowProps) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(1);

  const errorsColSpan =
    3 +
    (isNumber(imported) ? 1 : 0) +
    (isNumber(total) ? 1 : 0) +
    (isNumber(failed) ? 1 : 0) +
    (showReadyForImport ? 1 : 0) +
    (onDelete ? 1 : 0);

  return (
    <>
      <TableRow className={isOpen ? styles.backgroundGrey : ''}>
        <TableCell
          collapsing
          width={1}
          style={{ width: '8%', paddingRight: 0 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Icon
              link
              name={isOpen ? 'angle-down' : 'angle-right'}
              disabled={collapseDisabled}
              onClick={() => {
                if (collapseDisabled) return;

                setIsOpen(!isOpen);
                if (!isOpen && onOpen) {
                  onOpen();
                  setPage(1);
                }
              }}
              style={{
                marginRight: 15,
                color: collapseDisabled ? 'grey' : undefined,
                cursor: collapseDisabled ? 'default' : 'pointer',
              }}
            />
            {icon === 'loading' && <Loader active inline size="small" />}
            {icon === 'success' && (
              <Icon size="big" name="circle-check regular" color="green" />
            )}
            {icon === 'error' && (
              <Icon size="big" name="circle-cancel regular" color="red" />
            )}
            {icon === 'warning' && (
              <Icon size="big" name="circle-exclamation" color="orange" />
            )}
          </div>
        </TableCell>
        <TableCell collapsing width={3} style={{ paddingLeft: 0 }}>
          {file.type && <StatusLabel text={dataTypeTitle(t, file.type)} />}
        </TableCell>
        <TableCell collapsing width={4}>
          {t('dataImports.fileLabel', 'File')}: {file.originalFilename}
        </TableCell>
        {isNumber(imported) && (
          <TableCell collapsing width={2}>
            {t('dataImports.importedLabel', 'Imported')}:{' '}
            <Link
              to={filterUrlByType(file.type, dataImport.id)}
              target="_blank">
              <b>{imported}</b>
            </Link>
          </TableCell>
        )}
        {isNumber(total) && (
          <TableCell
            collapsing
            width={2}
            style={total <= 0 ? { color: 'grey' } : {}}>
            {t('dataImports.totalLabel', 'Total')}: <b>{total}</b>
          </TableCell>
        )}
        {isNumber(failed) && !showReadyForImport && (
          <TableCell
            collapsing
            width={2}
            style={failed <= 0 ? { color: 'grey' } : {}}>
            {t('dataImports.failed', 'Failed')}: <b>{failed}</b>
          </TableCell>
        )}
        {showReadyForImport && (
          <TableCell width={4}>
            <span style={{ color: 'grey' }}>
              {t('dataImports.readyForImport', 'Ready for import')}
            </span>
          </TableCell>
        )}
        {onDelete && (
          <TableCell collapsing width={1}>
            <Button
              basic
              icon="trash"
              title="Delete"
              onClick={onDelete}
              disabled={false}
            />
          </TableCell>
        )}
      </TableRow>
      {isOpen && !isEmpty(errors) && (
        <TableRow className={styles.backgroundGrey}>
          <TableCell colSpan={errorsColSpan}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <b>{t('dataImports.detectedErrors', 'Detected errors:')}</b>
              {onExportErrors && (
                <Button
                  basic
                  icon="download"
                  content={t('dataImports.exportErrors', 'Export errors')}
                  onClick={() => onExportErrors()}
                />
              )}
            </div>
            <Table celled>
              <TableBody>
                {errors?.map((error) => (
                  <TableRow key={error.message}>
                    <TableCell>{formatAPIError(error)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 10,
              }}>
              <Pagination
                className=""
                estimated=""
                limit={PAGINATION_SIZE}
                page={page}
                total={totalErrors}
                onPageChange={(e: any, { activePage }: { activePage: any }) => {
                  const newPage = parseInt(activePage);

                  setPage(newPage);
                  onErrorsPageChange(
                    PAGINATION_SIZE,
                    (newPage - 1) * PAGINATION_SIZE
                  );
                }}
              />
            </div>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
