import React, { createRef } from 'react';
import { Link } from 'react-router-dom';
import { request } from 'utils/api';
import SessionsTable from './SessionsTable';
import { Divider, Dropdown, Form, Segment } from 'semantic';
import { useTranslation } from 'react-i18next';
import { useUser } from 'contexts/user';
import AppWrapper from 'components/AppWrapper';
import { getMonthNames } from '../../utils/months';
import {
  Breadcrumbs,
  ListHeader,
  LoadButton,
  Search,
  SearchDropdown,
} from 'components';
import { ExportRangeV2 } from 'components/search';
import ExportButton from 'components/ExportButton';
import { useFeatures } from 'contexts/features';

const itemLimit = 100;

export default function SessionCPO() {
  const { t } = useTranslation();
  const { user } = useUser();
  const { hasFeature } = useFeatures();

  function onDataNeeded(filters) {
    filters.searchPhrase = filters.searchId;

    return request({
      method: 'POST',
      path: `/2/sessions/cpo/mine${!['csv', 'xlsx'].includes(filters.format) ? '/fast' : ''}`,
      body: {
        ...filters,
      },
    });
  }

  function exportLocationBreakdown(filters) {
    const { searchId, ...rest } = filters;
    return request({
      method: 'POST',
      path: '/2/sessions/cpo/mine/breakdown/location',
      body: {
        locationId: searchId,
        ...rest,
      },
    });
  }

  function exportEvseBreakdown(filters) {
    const { searchId, ...rest } = filters;
    return request({
      method: 'POST',
      path: '/2/sessions/cpo/mine/breakdown/evse',
      body: {
        locationId: searchId,
        ...rest,
      },
    });
  }

  const yearOptions = () => {
    return [
      {
        key: 0,
        text: t('myUsage.allYears', 'All years'),
        value: undefined,
      },
      ...[...new Array(10)].map((_, i) => {
        const year = new Date().getFullYear() - i;
        return {
          key: year,
          text: year,
          value: year,
        };
      }),
    ];
  };

  const monthOptions = () => {
    const monthNames = getMonthNames();
    return [
      {
        key: 0,
        text: t('myUsage.allMonths', 'All months'),
        value: 0,
      },
      ...Array.from({ length: 12 }, (v, i) => ({
        key: i + 1,
        text: monthNames[i],
        value: i + 1,
      })),
    ];
  };

  const paymentFlowOptions = () => {
    return [
      {
        text: t('myUsage.paymentMethod.chargeCard', 'Charge Card'),
        key: 'chargecard',
        value: 'charge-card',
      },
      {
        text: t('myUsage.paymentMethod.scanToPay', 'Scan to Pay'),
        key: 'scantopay',
        value: 'scan-to-pay',
      },
      {
        text: t('myUsage.paymentMethod.tapToPay', 'Tap to Pay'),
        key: 'taptopay',
        value: 'tap-to-pay',
      },
    ];
  };

  const positionRef = createRef();

  const hasExcelSupport = hasFeature('excel_support');

  return (
    <AppWrapper>
      <Search.Provider onDataNeeded={onDataNeeded} limit={itemLimit}>
        {({ filters, setFilters, loading }) => (
          <>
            <Breadcrumbs
              path={[
                <Link key="My Locations" to="/my-locations">
                  {t('myLocations.header', 'My Locations')}
                </Link>,
              ]}
              active={t('myUsage.sessionTitle', 'Charge Sessions')}
            />
            <ListHeader title={t('myUsage.sessionTitle', 'Charge Sessions')}>
              <div ref={positionRef} style={{ float: 'right' }}>
                {hasExcelSupport ? (
                  <Dropdown
                    button
                    basic
                    text={t('myUsage.exports', 'Exports')}
                    style={{ marginTop: '-5px' }}>
                    <Dropdown.Menu direction="left">
                      <Dropdown.Header>
                        {t('myUsage.allSessions', 'All Sessions')}
                      </Dropdown.Header>
                      <Dropdown.Divider />
                      <ExportRangeV2
                        positionRef={positionRef}
                        format="csv"
                        trigger={
                          <Dropdown.Item
                            basic
                            content={t(
                              'myUsage.exportAllSessionsCsv',
                              'Export All Sessions (Csv)'
                            )}
                            icon="download"
                          />
                        }
                      />
                      <ExportRangeV2
                        onRangeSubmit={onDataNeeded}
                        positionRef={positionRef}
                        format="xlsx"
                        trigger={
                          <Dropdown.Item
                            basic
                            content={t(
                              'myUsage.exportAllSessionsExcel',
                              'Export All Sessions (Excel)'
                            )}
                            icon="download"
                          />
                        }
                      />

                      <Dropdown.Header>
                        {t('myUsage.filteredSessions', 'Filtered Sessions')}
                      </Dropdown.Header>
                      <Dropdown.Divider />

                      <Search.Export
                        as={Dropdown.Item}
                        format="csv"
                        content={t(
                          'myUsage.exportFilteredSessionsCsv',
                          'Export Filtered Sessions (Csv)'
                        )}
                        filename="sessions-cpo"
                      />

                      <Search.Export
                        as={Dropdown.Item}
                        format="xlsx"
                        content={t(
                          'myUsage.exportFilteredSessionsExcel',
                          'Export Filtered Sessions (Excel)'
                        )}
                        filename="sessions-cpo"
                      />

                      <Dropdown.Header>
                        {t('myUsage.usageByLocation', 'Usage by Location')}
                      </Dropdown.Header>
                      <Dropdown.Divider />

                      <LoadButton
                        icon="download"
                        as={Dropdown.Item}
                        content={t(
                          'myUsage.exportUsageByLocationCsv',
                          'Export Usage by Location (Csv)'
                        )}
                        basic
                        onClick={() => {
                          return exportLocationBreakdown({
                            filters,
                            format: 'csv',
                          });
                        }}
                      />

                      <LoadButton
                        icon="download"
                        as={Dropdown.Item}
                        content={t(
                          'myUsage.exportUsageByLocationExcel',
                          'Export Usage by Location (Excel)'
                        )}
                        basic
                        onClick={() => {
                          return exportLocationBreakdown({
                            filters,
                            format: 'csv',
                          });
                        }}
                      />

                      <Dropdown.Header>
                        {t(
                          'myUsage.UsageByEvseController',
                          'Usage by EvseController'
                        )}
                      </Dropdown.Header>
                      <Dropdown.Divider />

                      <LoadButton
                        content={t(
                          'myUsage.exportUsageByEvseControllerCsv',
                          'Export Usage by EvseController (CSV)'
                        )}
                        basic
                        icon="download"
                        as={Dropdown.Item}
                        onClick={() => {
                          return exportEvseBreakdown({
                            ...filters,
                            format: 'csv',
                          });
                        }}
                      />
                      <LoadButton
                        content={t(
                          'myUsage.exportUsageByEvseControllerExcel',
                          'Export Usage by EvseController (Excel)'
                        )}
                        basic
                        icon="download"
                        as={Dropdown.Item}
                        onClick={() => {
                          return exportEvseBreakdown({
                            ...filters,
                            format: 'xlsx',
                          });
                        }}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <Dropdown
                    button
                    basic
                    text={t('myUsage.exports', 'Exports')}
                    style={{ marginTop: '-5px' }}>
                    <Dropdown.Menu direction="left">
                      <Search.Export
                        as={Dropdown.Item}
                        content={t('myUsage.exportSessions', 'Export Sessions')}
                        filename="sessions-cpo"
                      />
                      <LoadButton
                        as={Dropdown.Item}
                        content={t(
                          'myUsage.usageByLocation',
                          'Usage by Location'
                        )}
                        basic
                        icon="chart-pie"
                        onClick={() => {
                          return exportLocationBreakdown(filters);
                        }}
                      />
                      <LoadButton
                        content={t(
                          'myUsage.usageByEvseController',
                          'Usage by EvseController'
                        )}
                        basic
                        icon="plug"
                        as={Dropdown.Item}
                        onClick={() => {
                          return exportEvseBreakdown(filters);
                        }}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            </ListHeader>

            <Divider hidden />
            <Segment>
              <Form
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  columnGap: '15px',
                  flexWrap: 'wrap',
                }}>
                <Form.Field style={{ flexGrow: '1' }}>
                  <label>
                    {t('myUsage.filterByLocation', 'Filter by locations')}
                  </label>
                  <SearchDropdown
                    placeholder={t('myUsage.allLocations', 'All locations')}
                    clearable
                    objectMode={false}
                    onDataNeeded={(body) => {
                      return request({
                        path: '/2/locations/cpo/mine',
                        method: 'POST',
                        body: {
                          ...body,
                          locationsForUserId: user.id,
                        },
                      });
                    }}
                    onChange={(e, { value }) => {
                      setFilters({
                        ...filters,
                        searchId: value,
                      });
                    }}
                  />
                </Form.Field>
                <Form.Field style={{ flexGrow: '1' }}>
                  <label>{t('myUsage.filterByYear', 'Filter by year')}</label>
                  <Dropdown
                    placeholder={t('myUsage.allYears', 'All years')}
                    name="year"
                    fluid
                    key={filters.year}
                    value={filters.year}
                    selection
                    clearable
                    options={yearOptions()}
                    onChange={(e, { value }) => {
                      const newFilters = { ...filters, year: value };
                      if (!value) {
                        newFilters.month = null;
                      }

                      setFilters(newFilters);
                    }}
                  />
                </Form.Field>
                <Form.Field style={{ flexGrow: '1' }}>
                  <label>{t('myUsage.filterByMonth', 'Filter by month')}</label>
                  <Dropdown
                    key={filters.month}
                    value={filters.month}
                    placeholder={t('myUsage.allMonths', 'All months')}
                    fluid
                    selection
                    clearable
                    options={monthOptions()}
                    name="month"
                    onChange={(e, { value }) => {
                      const newFilters = { ...filters, month: value };

                      if (!filters.year && value) {
                        newFilters.year = new Date().getFullYear();
                      }
                      setFilters(newFilters);
                    }}
                  />
                </Form.Field>
                <Form.Field style={{ flexGrow: '1' }}>
                  <label>
                    {t('myUsage.filterByPaymentFlow', 'Payment method')}
                  </label>
                  <Dropdown
                    key={filters.paymentFlows}
                    value={filters.paymentFlows || []}
                    fluid
                    selection
                    multiple
                    clearable
                    options={paymentFlowOptions()}
                    name="paymentFlows"
                    onChange={(e, { value }) => {
                      setFilters({ ...filters, paymentFlows: value });
                    }}
                  />
                </Form.Field>
              </Form>
            </Segment>

            <Search.Status noResults={t('common.noResults', 'No Results')} />
            <SessionsTable />
            <Divider hidden />
            <div
              style={{
                textAlign: 'center',
              }}>
              <Search.Pagination />
            </div>
            {!loading && (
              <ExportButton
                content="Export"
                path={'/2/sessions/cpo/mine'}
                filename="sessions-cpo"
              />
            )}
          </>
        )}
      </Search.Provider>
    </AppWrapper>
  );
}
