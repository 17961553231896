import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import List2 from './List2';
import Detail from './Detail';
import { AppWrapper } from 'components';
import { Container } from 'semantic';
import { useFeatures } from 'contexts/features';

export default function Accounts() {
  const { hasFeature } = useFeatures();
  const multiEnabled = hasFeature('multi_selection_account_filter');

  return (
    <AppWrapper>
      <Container>
        <Switch>
          {!multiEnabled && <Route path="/accounts" component={List} exact />}
          {multiEnabled && <Route path="/accounts" component={List2} exact />}
          <Route path="/accounts/:id" component={Detail} />
        </Switch>
      </Container>
    </AppWrapper>
  );
}
