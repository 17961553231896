import React, { useContext } from 'react';
import { Confirm, Search } from 'components';
import { Button, Table } from 'semantic';
import { Trans, useTranslation } from 'react-i18next';
import { formatDateTime } from 'utils/date';
import { Link } from 'react-router-dom';
import {
  DATA_IMPORTS_BE_PATH,
  DATA_IMPORTS_FE_PATH,
  filterUrlByType,
  hasDataImportPermissions,
  renderDataImportFileType,
  USERS_FE_PATH,
} from 'screens/DataImports/utils';
import { DataImport, DataImportStatus } from 'types/data-import';
import DataImportStatusLabel from '../DataImportStatusLabel';
import { request } from 'utils/api';

export default function TableView() {
  const { items, loading, reload } = useContext(Search.Context);
  const { t } = useTranslation();

  if (!items.length || loading) return null;

  const dataImports: DataImport[] = items;

  const hasWriteAccess = hasDataImportPermissions('write');

  const handleDelete = async (id: string) => {
    await request({
      method: 'DELETE',
      path: `${DATA_IMPORTS_BE_PATH}/${id}`,
    });

    reload();
  };

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={1} singleLine>
            {t('dataImports.columnImportId', 'Import ID')}
          </Table.HeaderCell>
          <Table.HeaderCell width={4} singleLine>
            {t(
              'dataImports.columnNumberOfItemsInImport',
              'N of Items in import'
            )}
          </Table.HeaderCell>
          <Table.HeaderCell width={4} singleLine>
            {t('dataImports.columnDescription', 'Description')}
          </Table.HeaderCell>
          <Table.HeaderCell width={2} singleLine>
            {t('dataImports.columnCreatedAt', 'Created At')}
          </Table.HeaderCell>
          <Table.HeaderCell width={2} singleLine>
            {t('dataImports.columnCreatedBy', 'Created By')}
          </Table.HeaderCell>
          <Table.HeaderCell width={3} singleLine>
            {t('dataImports.columnStatus', 'Status')}
          </Table.HeaderCell>
          {hasWriteAccess && (
            <Table.HeaderCell width={3}>
              {t('dataImports.columnActions', 'Actions')}
            </Table.HeaderCell>
          )}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {dataImports.map((item) => {
          return (
            <Table.Row key={item.id}>
              <Table.Cell singleLine>
                <Link to={`${DATA_IMPORTS_FE_PATH}/${item.id}`}>
                  {item.id?.slice(-6)}
                </Link>
              </Table.Cell>
              <Table.Cell>
                <Table celled size="small" basic="very" textAlign="right">
                  <Table.Body>
                    {item.files.map((file) => {
                      return (
                        <Table.Row key={file.id}>
                          <Table.Cell width={8}>
                            {renderDataImportFileType(t, file.type)}
                          </Table.Cell>
                          <Table.Cell textAlign="left">
                            {file.fileMetrics?.statusesCount.completed > 0 ? (
                              <Link
                                to={filterUrlByType(file.type, item.id)}
                                target="_blank">
                                {file.fileMetrics?.totalCount || 'N/A'}
                              </Link>
                            ) : (
                              <>{file.fileMetrics?.totalCount || 'N/A'}</>
                            )}
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              </Table.Cell>
              <Table.Cell>{item.note}</Table.Cell>
              <Table.Cell singleLine>
                {formatDateTime(item.createdAt)}
              </Table.Cell>
              <Table.Cell singleLine>
                {item.creatorUser && (
                  <Link
                    to={`${USERS_FE_PATH}/${item.creatorUser.id}`}
                    target="_blank">
                    {item.creatorUser.contact?.firstName}{' '}
                    {item.creatorUser.contact?.lastName}
                  </Link>
                )}
                {item.createdBy && (
                  <Link
                    to={`${USERS_FE_PATH}?searchPhrase=${item.createdBy}`}
                    target="_blank">
                    {item.createdBy}
                  </Link>
                )}
              </Table.Cell>
              <Table.Cell>
                <DataImportStatusLabel status={item.status} />
                <p style={{ fontSize: 12, color: 'grey', paddingTop: 5 }}>
                  {item.files
                    .map((file) => {
                      if (!file.fileMetrics?.statusesCount?.failed) return;

                      return `${file.fileMetrics?.statusesCount?.failed} ${renderDataImportFileType(t, file.type)} failed`;
                    })
                    .filter(Boolean)
                    .join(', ')}
                </p>
              </Table.Cell>
              {hasWriteAccess && (
                <Table.Cell textAlign="center">
                  <Confirm
                    size="tiny"
                    header={t(
                      'dataImports.confirmDeleteTitle',
                      'Are you sure you want to delete this import?'
                    )}
                    content={t(
                      'dataImports.confirmDeleteDescription',
                      'This action will permanently delete the selected import.'
                    )}
                    trigger={
                      <Button
                        basic
                        icon="trash"
                        disabled={
                          item.status !== DataImportStatus.Draft &&
                          item.status !== DataImportStatus.Pending
                        }
                        title="Delete"
                      />
                    }
                    onConfirm={() => handleDelete(item.id)}
                  />
                </Table.Cell>
              )}
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
}
