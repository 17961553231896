import { Provider } from 'types/provider';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { formatCurrency } from 'utils/formatting';
import { SelectFieldOption } from 'components/form-fields/formik/SelectField';
import { useUser } from 'contexts/user';

export const usePaymentMethodOptions = (
  provider: Provider,
  countryCode?: string,
  currency?: string
) => {
  const { i18n, t } = useTranslation();

  const manualInvoicingFee = getManualInvoicingFeeWithVAT(provider, currency);

  const options: SelectFieldOption[] = [];

  if (useCanUseAutoPay()(countryCode || '')) {
    options.push({
      value: 'autopay',
      text: t('billingInfo.autopay', 'Autopay'),
      key: 'autopay',
    });
  }

  if (manualInvoicingFee) {
    options.push({
      value: 'manual',
      text: t(
        'billingInfo.manualPaymentWithFee',
        'Manual Payment ({{ fee }} per invoice)',
        {
          fee: manualInvoicingFee,
        }
      ),
      key: 'manual',
    });
  } else {
    options.push({
      value: 'manual',
      text: t('billingInfo.manualPayment', 'Manual Payment'),
      key: 'manual',
    });
  }

  return useMemo(() => {
    return options.filter((o: any) =>
      provider.supportedPaymentMethods.includes(o.value)
    );
  }, [provider, i18n, currency, options]);
};

export function getManualInvoicingFeeWithVAT(
  provider: Provider,
  currency?: string
): string {
  const vatPercentage = provider.individualFees / 100;

  const manualInvoicingFee =
    provider.currencies.find((c: any) => {
      return c.currency === currency;
    })?.manualInvoicingFee || 0;

  const formattedFee = formatCurrency(
    manualInvoicingFee * (vatPercentage + 1),
    {
      currency,
    }
  );

  return formattedFee;
}

export function useCanUseAutoPay() {
  const { provider } = useUser();
  return (countryCode: string) =>
    countryCode !== 'GB' &&
    provider?.supportedPaymentMethods?.includes?.('autopay');
}
