import {
  MerchantContractType,
  PaymentTerminalType,
  PayterPaymentMethod,
  PayterAuthorizationFlow,
  PaymentTerminalAcquirer,
} from 'types/payment-terminal';
import { TFunction } from 'react-i18next';

export const translationPaymentTerminalType = (
  t: TFunction,
  type: PaymentTerminalType
): string => {
  switch (type) {
    case 'payter':
      return t(
        'locationPaymentTerminal.contractTypePayter',
        'Cloud Terminal - Payter'
      );
    case 'alpitronic':
      return t(
        'locationPaymentTerminal.contractTypeAlpitronic',
        'Integrated Terminal - Alpitronic'
      );
    case 'sicharge':
      return t(
        'locationPaymentTerminal.contractTypeSiemens',
        'Integrated Terminal - Siemens'
      );
    case 'ads-tec':
      return t(
        'locationPaymentTerminal.contractTypeAdsTec',
        'Integrated Terminal - ADS-TEC'
      );
    case 'e-totem':
      return t(
        'locationPaymentTerminal.contractTypeETotem',
        'Integrated Terminal - e-Totem'
      );
    case 'charge-pay':
      return t(
        'locationPaymentTerminal.contractTypeChargePay',
        'Cloud Terminal - ChargePay'
      );
    case 'madic-lafon':
      return t(
        'locationPaymentTerminal.contractTypeMadicLafon',
        'Integrated Terminal - MADIC / LAFON'
      );
  }
};

export const translationMerchantContractType = (
  t: TFunction,
  contract: MerchantContractType
): string => {
  switch (contract) {
    case 'road':
      return t('locationPaymentTerminal.contractTypeRoad', 'Road');
    case 'customer':
      return t('locationPaymentTerminal.contractTypeCustomer', 'Customer');
  }
};

export const translationPaymentTerminalAcquirer = (
  t: TFunction,
  acquirer: PaymentTerminalAcquirer
): string => {
  switch (acquirer) {
    case 'elavon':
      return t('locationPaymentTerminal.acquirerElavon', 'Elavon');
    case 'worldline':
      return t('locationPaymentTerminal.acquirerWorldline', 'Worldline');
    case 'payone':
      return t('locationPaymentTerminal.acquirerPayone', 'Payone');
    case 'adyen-tsg':
      return t('locationPaymentTerminal.acquirerAdyenByTSG', 'Adyen by TSG');
  }
};

export const translationPayterPaymentMethod = (
  t: TFunction,
  method: PayterPaymentMethod
): string => {
  switch (method) {
    case 'card':
      return t('locationPaymentTerminal.payterPaymentMethodCard', 'Card');
    case 'card-rfid':
      return t(
        'locationPaymentTerminal.payterPaymentMethodCardAndRFID',
        'Card and RFID'
      );
  }
};

export const translationPayterAuthorizationFlow = (
  t: TFunction,
  method: PayterAuthorizationFlow
): string => {
  switch (method) {
    case 'connect-first':
      return t(
        'locationPaymentTerminal.payterAuthorizationFlowConnectFirst',
        'Connect Cable First'
      );
    case 'connect-or-authorize-first':
      return t(
        'locationPaymentTerminal.payterAuthorizationFlowConnectOrAuthorizeFirst',
        'Connect Cable or Authorize Card First'
      );
  }
};
