import React, { useRef, useState, useEffect } from 'react';
import { Button, Divider } from 'semantic';
import { currentUserCanAccess } from 'utils/roles';
import EditAccount from 'components/modals/EditAccount';
import ImportAccounts from 'components/modals/ImportAccounts';
import Table from './Table';
import { request } from 'utils/api';
import { Breadcrumbs, Search, ListHeader } from 'components';
import { useTranslation } from 'react-i18next';
import { AccountFilterHeader, accountFilterMapping } from './FilterHeader';
import { useLocation } from 'react-router';

export default function AccountsList() {
  const { t } = useTranslation();
  const searchRef = useRef(null);
  const [writeAccess, setWriteAccess] = useState(false);

  const searchParams = useLocation().search;
  const importId = new URLSearchParams(searchParams).get('importId');

  useEffect(() => {
    setWriteAccess(currentUserCanAccess('accounts', 'write'));
  }, []);

  const onDataNeeded = async (filters) => {
    if (importId) {
      filters.importId = importId;
    }

    if (filters.type) {
      filters.types = [filters.type];
      delete filters.type;
    }

    if (filters.billingPlans) {
      filters.accountTierBillingPlans = filters.billingPlans;
      delete filters.billingPlans;
    }

    if (filters.currencies) {
      filters.billingCurrencies = filters.currencies;
      delete filters.currencies;
    }

    if (filters.countries) {
      filters.billingCountries = filters.countries;
      delete filters.countries;
    }

    return request({
      method: 'POST',
      path: `/1/accounts/search${filters.format !== 'csv' ? '/fast' : ''}`,
      body: {
        ...filters,
        // this indicates that we want to include child provider accounts in the search if user has enough permissions
        maybeIncludeChildProviderAccounts: true,
      },
    });
  };

  return (
    <Search.Provider
      ref={searchRef}
      onDataNeeded={onDataNeeded}
      filterMapping={accountFilterMapping}>
      {() => {
        return (
          <>
            <Breadcrumbs active={t('accounts.title', 'Accounts')} />
            <ListHeader title={t('accounts.title', 'Accounts')}>
              <Search.Export filename="accounts" />
              <ImportAccounts
                onClose={() => searchRef.current?.reload()}
                trigger={
                  <Button
                    basic
                    style={{ marginTop: '1px' }}
                    content={t('accounts.importAccount', 'Import Accounts')}
                    icon="upload"
                    disabled={!writeAccess}
                  />
                }
              />
              <EditAccount
                onSave={() => searchRef.current?.reload()}
                trigger={
                  <Button
                    primary
                    style={{ marginTop: '1px' }}
                    disabled={!writeAccess}
                    content={t('accounts.newAccountBtn', 'New Account')}
                    icon="plus"
                  />
                }
              />
            </ListHeader>

            <AccountFilterHeader />

            <Search.Status noResults={t('common.noResults', 'No Results')} />
            <Table />
            <Divider hidden />
            <div style={{ textAlign: 'center' }}>
              <Search.Pagination />
            </div>
          </>
        );
      }}
    </Search.Provider>
  );
}
